import React from 'react';
import {
  ArcElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  registerables,
  Title,
  Tooltip
} from 'chart.js';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import pkg from '../package.json';

// import AccountsPage from './routes/accounts';
import AccountIndividualPage from './routes/account-individual';
import AddTradePage from './routes/add-trade';
import CalendarPage from './routes/calendar';
import ComparePage from './routes/compare';
import DailyTradeNotes from './routes/daily-trade-notes';
// import DailyTradesPage from './routes/daily-trades';
import Dashboard from './routes/dashboard';
import LayoutMain from './routes/layout';
import SettingsPage from './routes/settings';
import SummaryPage from './routes/summary';
import TradeIndividualPage from './routes/trade-individual';
import TradesPage from './routes/trades';
import useAuthentication from './utilities/hooks/auth';

import './App.css';
import './resources/styles/_icons.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retryDelay: 3000
    }
  }
});

console.log('[version]:', pkg.version);

function App() {

  const isUserSet = useAuthentication(); // Call useAuthentication hook

  // Redirect to '/' only after user is set
  if (!isUserSet) {

    return null; // or loading indicator if needed

  }

  Chart.register(
    ...registerables,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

  return (
      <React.StrictMode>
          <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                  <Routes>
                      <Route path='/' element={<LayoutMain />}>
                          <Route index element={<Dashboard />} />
                          <Route path='add-trade' element={<AddTradePage />} />
                          <Route path='summary' element={<SummaryPage />} />
                          <Route path='settings/:tabId?' element={<SettingsPage />} />
                          <Route path='accounts/:id' element={<AccountIndividualPage />} />
                          <Route path='trades' element={<TradesPage />} />
                          <Route path='trades/:id' element={<TradeIndividualPage />} />
                          <Route path='calendar' element={<CalendarPage />} />
                          <Route path='compare' element={<ComparePage />} />
                          <Route path='daily-trade-notes' element={<DailyTradeNotes />} />
                          {/* <Route path='daily' element={<DailyTradesPage />} /> */}
                      </Route>
                  </Routes>
              </BrowserRouter>
              <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
      </React.StrictMode>

  );

}

export default App;
