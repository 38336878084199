/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Collapse, DatePicker, Divider, InputNumber, message, Modal, Radio, Select, Space, Spin, Switch, Table, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';

import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { advancedSettingsInputs, dasCommissionOptions, iBCommissionOptions } from '../../resources/static/select-input-data';
import { useAccountStore, useSettingStore, useUserState } from '../../store';
import axios from '../../utilities/axios'; // dependent on utc plugin

import './styles.scss';

const { Text } = Typography;

const fetchCommissions = (userId) => {

  if (!userId) {

    return [];

  }

  const results = axios.get(`crud/commission/${userId}`)
    .then(({ data }) => data)
    .catch((error) => {

      if (error.response.status === 404) {

        return [];

      }
      return error;

    });

  return results;

};

export default function UploadBox() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { allAccounts, allCommissions, setAllCommissions, selectedAccount, setSelectedAccount } = useAccountStore((state) => state);
  const { advancedSettings, setAdvancedSettings, changeFilter, filter } = useSettingStore((state) => state);
  const [file, setFile] = useState('');
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const uploadRef = useRef(null);
  const [dateFormShow, setDateFormShow] = useState(false);
  const [selectedCommission, setSelectedCommission] = useState(null);
  const [radioValue, setRadioValue] = useState(null);
  const [autoTargetProfit, setAutoTargetProfit] = useState(5);
  const [autoStopLoss, setAutoStopLoss] = useState(-5);
  const [showCommissionDropdown, setShowCommissionDropdown] = useState(false);
  const [showTargeAndStopLossSection, setShowTargeAndStopLossSection] = useState(false);
  const [isLiveTrade, setisLiveTrade] = useState(false);
  const [liveTradeArray, setLiveTradeArray] = useState([]);
  const [messageApi] = message.useMessage();
  const [isAnyTradeNew, setIsAnyTradeNew] = useState(false);
  const queryClient = useQueryClient();
  const { getUser } = useUserState();
  const [accountKey, setAccountKey] = useState('');
  const user = getUser();

  const liveTradeAcount = {
    _id: 'Live_Trade',
    name: 'LiveTrade',
    broker: 'das_trader',
    brokerLabel: 'DasTrader',
    brokerTimezone: 'America/New_York',
    profitCalculationMethod: 'average',
    userId: 0
  };

  const tradeLiveCells = [
    {
      title: 'PCT ID',
      dataIndex: 'accountRef',
      key: 'accountRef'
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName'
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol'
    },
    {
      title: 'Side',
      dataIndex: 'side',
      key: 'side'
    },
    {
      title: 'Open Time',
      dataIndex: 'openTime',
      key: 'openTime',
      render: (openTime) => dayjs(openTime).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'Close Time',
      dataIndex: 'closeTime',
      key: 'closeTime',
      render: (closeTime) => (closeTime === null ? 'Open' : dayjs(closeTime).format('YYYY-MM-DD HH:mm:ss'))
    },
    {
      title: 'Open Price',
      dataIndex: 'openPrice',
      key: 'openPrice'
    },
    {
      title: 'Close Price',
      dataIndex: 'closePrice',
      key: 'closePrice'
    },
    {
      title: 'Total Volume',
      dataIndex: 'totalVolume',
      key: 'totalVolume'
    },
    {
      title: 'PCT ID Status',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (fullName) => (fullName !== '' ? <Text type='success'>Found</Text> : <Text type='danger'>Not Found</Text>)
    },
    {
      title: 'trade Status',
      dataIndex: 'activeStatus',
      key: 'activeStatus',
      render: (activeStatus) => {

        let color = { color: 'rgb(49, 130, 252)' };

        if (activeStatus === 'Not Found') {

          color = { color: '#ff4d4f' };

        } else if (activeStatus === 'New') {

          color = { color: '#52c41a' };

        }

        return <Text style={color}>{activeStatus}</Text>;

      }
    }
  ];

  useEffect(() => {

    if (!radioValue && Object.keys(selectedAccount).length !== 0) {

      setRadioValue(advancedSettings.options[accountKey]?.find((item) => ['noCommission', 'applyFileCommissions', 'forceOverwriteCommission'].includes(item)));

    }

    if (radioValue === 'forceOverwriteCommission') {

      setShowCommissionDropdown(true);
      setSelectedCommission(() => allCommissions.find((a) => a.userRef === user.id)?._id);

    }

  }, [radioValue, selectedAccount]);

  useEffect(() => {

    if (Object.keys(selectedAccount).length !== 0) {

      setRadioValue(advancedSettings.options[accountKey]?.find((item) => ['noCommission', 'applyFileCommissions', 'forceOverwriteCommission'].includes(item)));

      const accountOptions = advancedSettings.options[accountKey];
      const targetAndStopLoss = advancedSettings.targetProfitAndStopLoss[accountKey];

      if (!accountOptions) {

        setAdvancedSettings('options', { ...advancedSettings.options, [accountKey]: ['closeExpiredOptions', 'noCommission'] });
        setAdvancedSettings('targetProfitAndStopLoss', { ...advancedSettings.targetProfitAndStopLoss, [accountKey]: [5, -5] });

      } else {

        if (accountOptions.filter((item) => ['applyFileCommissions', 'noCommission'].includes(item)).length > 0) {

          setShowCommissionDropdown(false);

        } else if (accountOptions.filter((item) => ['forceOverwriteCommission'].includes(item)).length > 0) {

          setShowCommissionDropdown(true);

        }

        if (targetAndStopLoss) {

          setAutoTargetProfit(targetAndStopLoss[0]);
          setAutoStopLoss(targetAndStopLoss[1]);

        }

        setShowTargeAndStopLossSection(accountOptions.includes('applyTargetProfitAndStopLoss'));
        setSelectedCommission(advancedSettings.commissions[user.id]);

      }

    }

  }, [selectedAccount]);

  const { data = [] } = useQuery(['commissions'], () => fetchCommissions(user.id));

  useEffect(() => {

    if (data.length > 0) setAllCommissions(data);

  }, [data]);

  useEffect(() => {

    const hasNewTrade = liveTradeArray.some((trade) => trade.activeStatus === 'New');
    setIsAnyTradeNew(hasNewTrade);

  }, [liveTradeArray]);

  const submitLiveTradeConversion = (formData) => {

    setProcessing(true);

    axios.post('trades/Live-trade-conversion', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: 60000 * 5
    }).then((response) => {

      if (response && response.status === 200) {

        setLiveTradeArray(response.data.tradeResponse.filter((x) => x !== null));
        setIsModalOpen(true);

      }

    })
      .catch((err) => {

        if (err.response && err.response.data && err.response.data.message) {

          message.error(err.response.data.message);

        } else {

          message.error('Something went wrong. Please try again later.');

        }

      }).finally(() => {

        setProcessing(false);

      });

  };

  const uploadLiveTradeLog = (formData, selectDate) => {

    setProcessing(true);

    axios.post('trades/Live-trade-to-trades', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Account: liveTradeAcount.name,
        Broker: liveTradeAcount.broker,
        userId: user.id,
        selectDate
      },
      timeout: 60000 * 5
    }).then((response) => {

      if (response && response.status === 200) {

        message.success('Live Trade file was uploaded successfully. Trades have been attached to the PCT users.');
        setLiveTradeArray(response.data.tradeResponse.filter((x) => x !== null));
        setIsModalOpen(false);

        uploadRef.current.value = '';
        setFile('');
        navigate('/');

      }

    })
      .catch((err) => {

        setProcessing((prev) => !prev);

        if (err.response && err.response.data && err.response.data.message) {

          message.error(err.response.data.message);

        } else {

          message.error('Something went wrong. Please try again later.');

        }

      }).finally(() => {

        messageApi.destroy();

        setProcessing(false);

      });

  };

  const uploadExecutionsLog = async (formData, selectDate) => {

    setProcessing(true);

    try {
      await axios.post('trades/executions-to-trades', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Account: selectedAccount.key,
          Broker: selectedAccount.broker,
          userId: user.id,
          selectDate
        }
      });

      queryClient.refetchQueries('trades');


      message.success('File uploaded successfully. Processed trade logs!');
      setProcessing(false);
      navigate('/');

    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        message.error(err.response.data.message);
      } else {
        message.error(err.message ? err.message : 'Something went wrong. Please try again later.');
      }
    } finally {
      setProcessing(false);
    }
  };

  const submitFile = async (e, uploadLiveTrade = false) => {

    e.preventDefault();

    if (dateFormShow && !date) {

      message.error('Please select a date');
      return;

    }

    if (!file || file === '') {

      message.error('Please select a file');
      return;

    }

    const account = isLiveTrade === true ? liveTradeAcount : selectedAccount;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('date', dayjs(date).format('YYYY-MM-DD'));
    formData.append('broker', account.broker);
    formData.append('brokerTimezone', account.brokerTimezone);
    formData.append('isLiveTrade', isLiveTrade);

    if (radioValue === 'forceOverwriteCommission') {

      if (selectedCommission) {

        formData.append('commissionID', selectedCommission);
        formData.append('commissionProfileName', data.find((a) => a._id === selectedCommission).name);
        setAdvancedSettings('commissions', { ...advancedSettings.commissions, [user.id]: selectedCommission });

      }

    }

    formData.append('fileName', file.name);
    formData.append('accountID', account.key);
    formData.append('method', account.profitCalculationMethod);
    formData.append('advancedSettingsOptions', advancedSettings.options[accountKey]);

    if (advancedSettings.options[accountKey]?.includes('applyTargetProfitAndStopLoss')) {

      formData.append('autoTargetProfit', Number(autoTargetProfit) / 100 || 0);
      formData.append('autoStopLoss', Number(autoStopLoss) / 100 || 0);

      setAdvancedSettings('targetProfitAndStopLoss', { ...advancedSettings.targetProfitAndStopLoss, [accountKey]: [autoTargetProfit, autoStopLoss] });

    }

    if (isLiveTrade === true && uploadLiveTrade === false) {

      submitLiveTradeConversion(formData);

    } else if (isLiveTrade === true && uploadLiveTrade === true) {

      uploadLiveTradeLog(formData, dayjs(date).format('YYYY-MM-DD'));

    } else {

      uploadExecutionsLog(formData, dayjs(date).format('YYYY-MM-DD'));

    }

  };

  // Check if broker requires special form fields
  const brokerCheck = (b) => {

    setDateFormShow(b === 'das_trader');

  };

  const handleUploadChange = (e) => {

    if (e.target.files.length > 1) {

      message.error('You can not upload more than one file!');
      uploadRef.current.value = '';
      return;

    }
    setFile(e.target.files[0]);

  };

  const handleCommissionSelect = (e) => setSelectedCommission(() => allCommissions.find((a) => a._id === e)._id);

  const handleCommissionTypeChange = (e) => {

    let newSettings = advancedSettings.options[accountKey] || [];

    if (e.target.value === 'forceOverwriteCommission') {

      setShowCommissionDropdown(true);

      if (!newSettings.includes('forceOverwriteCommission')) {

        const filteredSettings = newSettings.filter((item) => !['applyFileCommissions', 'noCommission'].includes(item));
        newSettings = [...filteredSettings, 'forceOverwriteCommission'];
        setSelectedCommission(allCommissions.filter((a) => a.accountRef === user.id)[0]?._id);

      }

    } else if (e.target.value === 'applyFileCommissions') {

      if (!newSettings.includes('applyFileCommissions')) {

        const filteredSettings = newSettings.filter((item) => !['forceOverwriteCommission', 'noCommission'].includes(item));
        newSettings = [...filteredSettings, 'applyFileCommissions'];

      }
      setShowCommissionDropdown(false);
      setSelectedCommission(() => null);

    } else {

      if (!newSettings.includes('noCommission')) {

        const filteredSettings = newSettings.filter((item) => !['forceOverwriteCommission', 'applyFileCommissions'].includes(item));
        newSettings = [...filteredSettings, 'noCommission'];

      }
      setShowCommissionDropdown(false);
      setSelectedCommission(() => null);

    }

    setAdvancedSettings('options', { ...advancedSettings.options, [accountKey]: [...newSettings] });

    setRadioValue(e.target.value);

  };

  useEffect(() => {

    if (Object.keys(selectedAccount).length !== 0) {

      brokerCheck(selectedAccount.broker);
      setAccountKey(isLiveTrade === true ? 'Live_Trade' : selectedAccount.key);

    }

  }, [selectedAccount]);

  const handleCloseExpiredChange = (e) => {

    const newSettings = e.includes('closeExpiredOptions') ?
      [...advancedSettings.options[accountKey], e[0]] :
      advancedSettings.options[accountKey].filter((item) => item !== 'closeExpiredOptions');

    setAdvancedSettings('options', { ...advancedSettings.options, [accountKey]: [...newSettings] });

  };

  const handleStopLossChange = (e) => {

    const newSettings = e.includes('applyTargetProfitAndStopLoss') ?
      [...advancedSettings.options[accountKey], 'applyTargetProfitAndStopLoss'] :
      advancedSettings.options[accountKey].filter((item) => item !== 'applyTargetProfitAndStopLoss');

    setAdvancedSettings('options', { ...advancedSettings.options, [accountKey]: [...newSettings] });

    setShowTargeAndStopLossSection(e.includes('applyTargetProfitAndStopLoss'));

  };

  return (
    <div className='container'>
      <Spin className='upload-spin' indicator={<LoadingOutlined />}
        spinning={processing} tip='Processing uploaded file...' >

        {/* TODO: Use antd form instead. */}
        <form id='form' onSubmit={submitFile} encType='multipart/form-data'>

          {user.role === 'admin' &&
            <div className='input-group input-switch-controller'>
              <label htmlFor='accounts'>Are you uploading for users?</label>
              <Switch size='small' onChange={(type) => {

                setisLiveTrade(type);
                setAccountKey(type === true ? 'Live_Trade' : selectedAccount.key);

              }} checkedChildren='Yes' unCheckedChildren='No' />
            </div>}
          {isLiveTrade === false &&
            <div className='input-group'>
              <label htmlFor='accounts'>Account: {isLiveTrade === true && <span>Live Trade</span>}</label>

              <Select
                id='accounts'
                options={allAccounts.filter((x) => x.label !== 'Live Trade')}
                defaultValue={allAccounts.filter((x) => x.label !== 'Live Trade')[0]}
                style={{ width: '100%' }}
                value={selectedAccount}
                onChange={(e) => setSelectedAccount(allAccounts.filter((x) => x.label !== 'Live Trade').find((a) => a.key === e))}
              />
            </div>}
          <div className={`input-group ${dateFormShow === true || isLiveTrade === true ? '' : 'hide'}`}>
            <label htmlFor='date'>Date:</label>
            <DatePicker
              id='date'
              value={date}
              onChange={setDate}
              style={{ width: '100%' }} />
          </div>
          <div className='input-group'>
            <label>Broker: {isLiveTrade === true ? liveTradeAcount.brokerLabel : selectedAccount.brokerLabel}</label>
          </div>
          <div className='input-group'>
            <label>Timezone: {isLiveTrade === true ? liveTradeAcount.brokerTimezone : selectedAccount.brokerTimezone}</label>
          </div>
          <div className='input-group'>
            {/* <label htmlFor='files'>File:</label> */}
            <input id='files' ref={uploadRef} type='file' multiple onChange={handleUploadChange} />
          </div>
          <Collapse
            items={[
              {
                key: '1',
                label: 'Advanced Settings',
                children: <Space size={[0, 12]} direction='vertical'>
                  <Checkbox.Group
                    options={advancedSettingsInputs.options}
                    defaultValue={advancedSettings.options[accountKey]?.includes('closeExpiredOptions') ? 'closeExpiredOptions' : ''}
                    onChange={handleCloseExpiredChange} />
                  <Checkbox.Group
                    options={advancedSettingsInputs.target_and_stop_loss}
                    value={advancedSettings.options[accountKey]?.includes('applyTargetProfitAndStopLoss') ? 'applyTargetProfitAndStopLoss' : ''}
                    onChange={handleStopLossChange} />
                  {showTargeAndStopLossSection ?
                    <>
                      <Text type='primary'>Target Profit: </Text>
                      <InputNumber
                        value={autoTargetProfit}
                        min={0}
                        max={1000}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value?.replace('%', '')}
                        onChange={setAutoTargetProfit} />
                      <Text type='primary'>Stop Loss: </Text>
                      <InputNumber
                        defaultValue={autoStopLoss}
                        min={-99}
                        max={0}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value?.replace('%', '')}
                        onChange={setAutoStopLoss} />
                    </>
                    : <></>}
                  <Text strong>Commissions and Fees</Text>
                  <Radio.Group
                    options={selectedAccount.broker === 'das_trader' ? dasCommissionOptions : iBCommissionOptions}
                    onChange={handleCommissionTypeChange}
                    value={
                      radioValue ||
                      advancedSettings.options[accountKey]?.find(
                        (item) => ['noCommission', 'applyFileCommissions', 'forceOverwriteCommission'].includes(item)
                      )
                    } />
                  {showCommissionDropdown ?
                    allCommissions.length > 0 ?
                      <Select
                        id='accounts'
                        defaultValue={allCommissions.filter((a) => a.userRef === user.id)[0]._id}
                        value={selectedCommission}
                        style={{ width: '100%' }}
                        onChange={handleCommissionSelect}
                        options={allCommissions.filter((a) => a.userRef === user.id)
                          .map((c) => ({ label: c.name, value: c._id }))}
                      /> : <Text type='secondary'>No commission profiles to select <Tooltip title='Please add a new commission profile in Settings > Commissions & Fees.'>
                        <InfoCircleOutlined /></Tooltip></Text> : <></>}
                </Space>
              }
            ]} />
          <Divider />
          <Button htmlType='submit' disabled={processing} loading={processing} className='submit-btn' type='primary' size='large' >Upload</Button>
        </form>
      </Spin>
      <Modal
        width={'90%'}
        title='Live Trade'
        open={isModalOpen}
        onOk={(e) => submitFile(e, true)}
        onCancel={() => {

          messageApi.destroy();
          setFile('');
          uploadRef.current.value = '';
          setIsModalOpen(false);

        }}
        okText='Submit'
        maskClosable={!processing}
        okButtonProps={{ loading: processing, disabled: !isAnyTradeNew }}>
        <Table dataSource={liveTradeArray} columns={tradeLiveCells} />
      </Modal>
    </div>
  );

}
