import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { useManagementStore, useUserState } from '../../store';
import AccountsPage from '../accounts';
import CommissionsPage from '../commissions';
import FileManagementPage from '../file-management';
import TagManagementPage from '../tag-management';
import UserManagementPage from '../user-management';

import './styles.scss';

const items = [
  {
    key: '0',
    label: 'Account',
    disabled: true
  },
  {
    key: '1',
    label: 'Accounts',
    children: <AccountsPage />
  },
  {
    key: '2',
    label: 'Commissions & Fees',
    children: <CommissionsPage />
  },
  {
    key: '3',
    label: 'Tags Management',
    children: <TagManagementPage />
  }
];

const itemsAdmin = [
  ...items,
  {
    key: '7',
    label: 'Admin',
    disabled: true
  },
  {
    key: '4',
    label: 'User Management',
    children: <UserManagementPage />
  },
  {
    key: '5',
    label: 'File Management',
    children: <FileManagementPage />
  }
];

export default function SettingsPage() {

  const { getUser } = useUserState();
  const user = getUser();
  const { viewAs, setViewAs } = useManagementStore((state) => state);
  const [activeTab, setActiveTabKey] = useState('1');
  const navigate = useNavigate();
  const { tabId } = useParams();

  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);

  if (isViewAsActive) setViewAs({ token: user?.token, id: user?.id }, { token: user?.token, id: user?.id });

  useEffect(() => {

    if (tabId) {

      const key = user.role === 'admin'
        ? itemsAdmin.find((x) => x.key === tabId)?.key
        : items.find((x) => x.key === tabId)?.key;

      if (key) {

        setActiveTabKey(key);

      }

    }

  }, [tabId, user.role]);

  return (
      <>
          {user.role === 'admin' ? (
              <Tabs
                  className='settingTab'
                  tabPosition='left'
                  activeKey={activeTab}
                  style={{ width: '100%' }}
                  items={itemsAdmin}
                  onChange={(key) => navigate(`/settings/${key}`)}

        />
          ) : (
              <Tabs
                  className='settingTab'
                  tabPosition='left'
                  activeKey={activeTab}
                  style={{ width: '100%' }}
                  items={items}
                  onChange={(key) => navigate(`/settings/${key}`)}
        />
          )}
      </>
  );

}
