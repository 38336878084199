/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Avatar, Button, Card, Carousel, Col, Dropdown, Image, Menu, message, Modal, Popconfirm, Typography, Upload } from 'antd';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import { EyeOutlined, PictureOutlined } from '@ant-design/icons';
import { ListItemNode, ListNode } from '@lexical/list';
import { MarkNode } from '@lexical/mark';
import { OverflowNode } from '@lexical/overflow';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';

import NotesTemplateEditor from '../../../components/notes-editor/components/NotesTemplateEditor';
import { CollapsibleContainerNode } from '../../../components/notes-editor/plugins/CollapsiblePlugin/CollapsibleContainerNode';
import { CollapsibleContentNode } from '../../../components/notes-editor/plugins/CollapsiblePlugin/CollapsibleContentNode';
import { CollapsibleTitleNode } from '../../../components/notes-editor/plugins/CollapsiblePlugin/CollapsibleTitleNode';
import theme from '../../../components/notes-editor/theme';
import PersonImg from '../../../resources/images/person.svg';
import { useUserState } from '../../../store';
import axios from '../../../utilities/axios';
import getTimeDifference from '../../../utilities/relativeTime';

const { Text } = Typography;

function isLexicalContent(content) {

  try {

    const jsonContent = JSON.parse(content);
    return jsonContent && typeof jsonContent === 'object' && jsonContent.root;

  } catch (error) {

    return false;

  }

}

function InitialStatePlugin({ initialState }) {

  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {

    if (initialState) {

      const parsedState = editor.parseEditorState(initialState);
      if (!parsedState.isEmpty()) {

        editor.setEditorState(parsedState);

      }

    }

  }, [editor, initialState]);

  return null;

}

InitialStatePlugin.propTypes = {
  initialState: PropTypes.string
};

const initialConfig = {
  namespace: 'ReadOnlyEditor',
  nodes: [
    ListNode,
    ListItemNode,
    OverflowNode,
    MarkNode,
    CollapsibleContainerNode,
    CollapsibleContentNode,
    CollapsibleTitleNode
  ],
  editable: false,
  onError: (error) => console.error(error),
  theme
};

export default function TradeNotes({ tradeNotes, setTradeNotes }) {

  const allowedFileTypes = ['image/png', 'image/jpeg', 'image/gif'];
  const [notesContent, setNotesContent] = useState('');
  const [existingContent, setExistingContent] = useState('');
  const [noteId, setNoteId] = useState('');
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [imageModalItems, setImageModalItems] = useState([]);
  const [imageSelectedIndex, setImageSelectedIndex] = useState(0);

  const [fileList, setFileList] = useState([]);

  const { id } = useParams();
  const { getUser } = useUserState();
  const user = getUser();

  const addOrUpdateNotesToTrade = (notes) => {

    const formData = new FormData();
    formData.append('id', notes.id);
    formData.append('content', notes.content);
    formData.append('userId', notes.userId);
    formData.append('date', notes.date);

    fileList.forEach((file) => {

      formData.append('files', file.originFileObj);

    });

    axios
      .put(`crud/trades/notes/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {

        const updatedNotes = [...tradeNotes];
        const existingNoteIndex = updatedNotes.findIndex((note) => note._id === notes.id);

        if (existingNoteIndex !== -1) {

          updatedNotes[existingNoteIndex].content = notes.content;
          const noteSelected = data.notes.find((note) => note._id === notes.id);
          updatedNotes[existingNoteIndex].images = noteSelected.images;
          setTradeNotes((x) => (x === notes.id ? { ...x, content: notes.content } : x));

        } else {

          const newNote = {
            _id: data.notes[data.notes.length - 1]._id,
            content: notes.content,
            createDate: notes.date,
            userId: {
              first_name: user.name,
              last_name: user.lastName,
              image: user.avatar,
              _id: user.id
            },
            images: data.notes[data.notes.length - 1].images
          };

          updatedNotes.push(newNote);
          setTradeNotes([...tradeNotes, newNote]);

        }

        setExistingContent(() => null);
        setTradeNotes(updatedNotes);
        setFileList([]);
        message.success('Note submitted.');
        const section = document.querySelector('#notes');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });

      }).catch((ex) => {

        const errorMessage = ex?.response?.data?.error || '';

        setNoteId('');
        setExistingContent(() => null);
        setFileList([]);
        message.error(errorMessage || 'Failed to save notes.', 3);

      });

  };

  const deleteTradeNote = async (_noteid) => {

    try {

      await axios.delete(`crud/trades/notes/${id}/${_noteid}`);
      setTradeNotes((prevNotes) => prevNotes.filter((note) => note._id !== _noteid));

      message.success('Note removed!');

    } catch (error) {

      message.error('Failed to delete trade note.');

    }

  };

  const deleteTradeNoteImage = async (_noteid, _imageid) => {

    try {

      await axios.delete(`crud/trades/notes/image/${id}/${_noteid}/${_imageid}`);

      setTradeNotes((prevNotes) => prevNotes.map((note) => (note._id === _noteid
        ? { ...note, images: note.images.filter((image) => image._id !== _imageid) }
        : note)));

      message.success('Image removed!');

    } catch (error) {

      message.error('Failed to delete image.');

    }

  };

  const save = () => {

    const newNote = {
      id: noteId,
      content: notesContent,
      userId: user.id,
      date: new Date().toISOString()
    };

    addOrUpdateNotesToTrade(newNote);
    setExistingContent('');
    setNoteId('');

  };

  const SubmitButton = () => (
      <Button
          type='primary'
          disabled={(!notesContent || notesContent.length === 0) && fileList.length === 0}
          onClick={save}>{noteId !== '' ? 'Update' : 'Submit'}
      </Button>
  );
  const CancelButton = () => (<Button onClick={() => {

    setNoteId('');
    setExistingContent('');
    setFileList([]);

  }}>Cancel</Button>);

  const handleEdit = (record) => {

    setExistingContent(record.content);
    setNoteId(record._id);

    const newFileList = record.images.map((image, index) => ({
      uid: index,
      name: `image-${index}`,
      status: 'done',
      url: image.path,
      id: image._id,
      noteId: record._id
    }));

    setFileList(newFileList);

  };

  const handleChange = ({ fileList: newFileList }) => {

    const hasInvalidFile = newFileList.some((file) => !file.id && !allowedFileTypes.includes(file.type));

    if (hasInvalidFile) {

      message.error('You can only upload PNG, JPG, and GIF files!');
      const validFiles = newFileList.filter((file) => allowedFileTypes.includes(file.type));
      setFileList(validFiles);

    } else {

      setFileList(newFileList);

    }

  };

  const handlePreview = (images, imageIndex) => {

    setImageModalVisible(!imageModalVisible);
    setImageModalItems(images);
    setImageSelectedIndex(imageIndex);

  };

  const ThreeDotMenu = ({ record }) => {

    const menu = (
        <Menu className='noteMenu'>
            <Menu.Item onClick={() => handleEdit(record)} key='1'>
                <Button type='link' href='#editoSection'><i className='tt-edit' />&nbsp;Edit</Button>
            </Menu.Item>
            <Menu.Item key='2'>
                <Popconfirm
                    title='Remove Note?'
                    description='This will delete note in this trade. Are you sure?'
                    onConfirm={() => deleteTradeNote(record._id)}
                    okText='Yes'
                    cancelText='No'
                    placement='top' >
                    <Text type='danger'><i className='tt-trash' />&nbsp;Delete</Text>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    return (

        <Dropdown className='tools' overlay={menu} trigger={['click']}>
            <Button type='text' icon={<span className='tt-more' style={{ fontSize: '1.5rem' }} />} />
        </Dropdown>
    );

  };

  ThreeDotMenu.propTypes = {
    record: PropTypes.object
  };

  return (
      <Col span={24}>
          <Card
              className='cardTradeNotes'
              title='Trade Notes'
              id='notes'
              bordered={true} >
              <div>
                  {tradeNotes
                    .filter((x) => x.content !== '' || x.images.length !== 0)
                    .slice()
                    .reverse()
                    .map((record, key) => <div className='noteItem' key={key}>
                        <Avatar
                            className='user-avatar'
                            size={'2rem'}
                            src={record.userId?.image || PersonImg} />
                        <div className='noteContent'>
                            <h3 className='note-title'>
                                <span className='user'>{record.userId ? `${record.userId.first_name} ${record.userId.last_name}` : 'Unknown'}</span>
                                <span className='time'>
                                    {(record.createDate ? getTimeDifference(record.createDate) : '')}
                                </span>
                            </h3>
                            {isLexicalContent(record.content) ?
                                <LexicalComposer
                                    key={record._id}
                                    initialConfig={initialConfig}
                            >
                                    <PlainTextPlugin
                                        contentEditable={<ContentEditable />}
                                        placeholder={<div>Loading...</div>}
                                />
                                    <InitialStatePlugin initialState={record.content} />
                                    <ListPlugin />
                                    <CheckListPlugin />

                                </LexicalComposer>
                              : <p>{record.content}</p>}
                            {record.images && record.images.length > 0 &&
                                <div className='noteImages'>
                                    {record.images && record.images.length > 0 && (
                                    <>
                                        {record.images.map((img, imgKey) => (
                                            <div className='image' key={imgKey}>
                                                <Image
                                                    alt={record.content}
                                                    src={img.path}
                                                    height={80}
                                                    preview={{
                                                      visible: false,
                                                      onVisibleChange: () => {

                                                        handlePreview(record.images, imgKey);

                                                      },
                                                      mask: (
                                                          <div className='imageTools' >
                                                              <Button type='link' ><EyeOutlined /></Button>
                                                          </div>
                                                      )
                                                    }} />
                                            </div>
                                        ))}
                                    </>
                                    )}
                                </div>

                            }
                        </div>
                        {record.userId?._id === user.id && (<ThreeDotMenu record={record} />)}
                    </div>)

            }
              </div>
              <div className='cardFooter' id='editoSection'>
                  <h2 className='note-section-title'>{noteId !== '' ? 'Edit' : 'Add'} Note</h2>
                  <NotesTemplateEditor
                      setNotesContent={setNotesContent}
                      existingContent={existingContent}
                      setExistingContent={setExistingContent}
                      SubmitButton={SubmitButton}
                      CancelButton={noteId !== '' ? CancelButton : () => <span />}
              />
                  <div className='buttonBox'>
                      <Upload
                          listType='picture-card'
                          fileList={fileList}
                          onChange={handleChange}
                          onRemove={(file) => {

                            if (file.id && file.id !== '') {

                              deleteTradeNoteImage(file.noteId, file.id);

                            }
                            setFileList(fileList.filter((f) => f.uid !== file.uid));

                          }}
                          beforeUpload={() => false} >
                          {fileList.length >= 3 ? null : (
                              <div>
                                  <PictureOutlined />
                                  <div style={{ marginTop: 8 }}>Upload</div>
                              </div>

                          )}
                      </Upload>
                  </div>
              </div>
          </Card>
          {imageModalItems.length > 0 &&
          <Modal
              title={imageModalItems.length > 0 ? imageModalItems[0].selectedImage : 'image'}
              className='ImageModal'
              open={imageModalVisible}
              width='90%'
              onCancel={() => {

                setImageModalVisible(false);
                setImageModalItems([]);

              }
          }
              footer={null}>
              <Carousel
                  arrows={true}
                  dots={true}
                  infinite={true}
                  autoplay={false}
                  draggable={true}
                  initialSlide={imageSelectedIndex}
                  style={{ width: '100%', minHeight: 200 }}
                  >
                  {imageModalItems.map((img, imgKey) => (
                      <div key={imgKey} className='imageBox'>
                          <Image
                              preview={false}
                              alt='Note Image'
                              src={img.path}
                              style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                />
                      </div>
                  ))}
              </Carousel>

          </Modal>}
      </Col>
  );

}
