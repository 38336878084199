import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import axios from '../utilities/axios';
import config from '../utilities/config';
import { getCookie, removeCookie, setCookie } from '../utilities/cookie';

const CURRENT_VERSION = 3; // Increment this number when you make breaking changes

// Clears LocalStorage if user version is smaller than new version
const initializeLocalStorage = (storageKey, currentVersion) => {

  const storedState = JSON.parse(localStorage.getItem(storageKey));
  if (storedState?.version < currentVersion) {

    localStorage.removeItem(storageKey);

  }

};

const resetDateFilter = () => {

  const settingStorage = JSON.parse(localStorage.getItem('setting-storage'));
  const settingStorageFilter = settingStorage?.state?.filter;
  localStorage.removeItem('last-trade-day');
  localStorage.removeItem('first-trade-day');

  if (settingStorageFilter?.dateRange) {

    const newSettings = { ...settingStorageFilter, dateRange: null };
    localStorage.setItem('setting-storage', JSON.stringify({ ...settingStorage, state: { ...settingStorage.state, filter: newSettings } }));

  }

};

initializeLocalStorage('import-drawer', CURRENT_VERSION);
initializeLocalStorage('setting-storage', CURRENT_VERSION);
initializeLocalStorage('manager-storage', CURRENT_VERSION);
resetDateFilter();

const useAccountStore = create(
  persist(
    (set) => ({
      account: {},
      allAccounts: [],
      allCommissions: [],
      selectedAccount: {},
      setSelectedAccount: (newAccount) => set((state) => ({ selectedAccount: state.allAccounts.find((o) => o.key === newAccount?.key) })),
      setAllAccounts: (accounts) => set(() => ({ allAccounts: accounts })),
      changeAccount: (newSelectedAccount) => set((state) => ({ account: state.allAccounts.find((o) => o.key === newSelectedAccount?.key) })),
      setAllCommissions: (commissions) => set(() => ({ allCommissions: commissions }))
    }),
    {
      name: 'user-storage',
      version: CURRENT_VERSION,
      partialize: (state) => ({ account: state.account, allAccounts: state.allAccounts, allCommissions: state.allCommissions })
      // storage: createJSONStorage(() => sessionStorage),
      // onSet: (state) => {
      //   // Ensure that filter.accounts stays in sync with allAccounts
      //   state.filter.accounts = state.allAccounts;
      // },
    }
  )
);

const useImportDrawerStore = create(
  persist(
    (set) => ({
      importDrawerOpen: false,
      setImportDrawerOpen: (isOpen) => set(() => ({ importDrawerOpen: isOpen }))
    }),
    {
      name: 'import-drawer',
      version: CURRENT_VERSION,
      partialize: (state) => ({ importDrawerOpen: state.importDrawerOpen })
    }
  )
);

const useSettingStore = create(
  persist(
    (set) => ({
      filter: {
        assetType: ['Stock', 'Option', 'Future', 'Cash'],
        dateRange: null,
        tags: [],
        showAllTags: false,
        accounts: [],
        symbols: [],
        status: ['open', 'closed'],
        side: ['Long', 'Short'],
        basic_measure: 'Dollar'
      },
      advancedSettings: { options: {}, executions: [], commissions: {}, targetProfitAndStopLoss: {} },
      marks: [],
      changeFilter: (key, value) => set((state) => ({
        filter: {
          ...state.filter,
          [key]: value
        }
      })),
      setAdvancedSettings: (key, value) => set((state) => ({
        advancedSettings: {
          ...state.advancedSettings,
          [key]: value
        }
      })),
      setMarks: (value) => set(() => ({ marks: value }))
    }),

    {
      name: 'setting-storage',
      version: CURRENT_VERSION,
      partialize: (state) => ({ filter: state.filter, advancedSettings: state.advancedSettings })
    }
  )
);

const storedUser = JSON.parse(getCookie('user') || 'false');

const useManagementStore = create(
  persist(
    (set) => ({
      viewAs: {
        newUser: {
          token: {},
          id: {}
        },
        mainUser: {
          token: {},
          id: {}
        }
      },
      allUsers: [],
      setViewAs: (newToken, mainToken) => set(() => ({ viewAs: {
        newUser: newToken,
        mainUser: mainToken
      } })),
      setAllUsers: (users) => set(() => ({ allUsers: users }))
    }),
    {
      name: 'manager-storage',
      version: CURRENT_VERSION,
      partialize: (state) => ({ viewAs: state.viewAs, allUsers: state.allUsers })
    }
  )
);
// const storedUser = JSON.parse(
//   getCookie('user') || 'false'
// ) ||
//   { name: '',
//     email: '',
//     lastName: '',
//     avatar: '',
//     token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzNDcyMWMxYWQ2ODM1NGU5ODU2ZmUyMSIsImlhdCI6MTY5MTUwODIwMn0.oqB5Mw2q_1CJuhqb_NainYndcSw5sgIaStwSBepRDr4',
//     darkMode: false };

const userStore = create((set) => ({
  user: { ...storedUser },

  setUser: async ({ name, email, lastName, avatar, token, darkMode, role, id }) => {

    const user = { name, email, lastName, avatar, token, darkMode, role, id };

    setCookie('user', user);

    return set(() => ({
      user
    }));

  },

  updateUser: ({ name, lastName }) => set((state) => ({
    user: { ...state.user, name, lastName }
  })),

  updateToken: ({ token }) => set((state) => ({
    user: { ...state.user, token }
  })),

  updateDarkMode: ({ mode }) => set((state) => ({
    user: { ...state.user, darkMode: mode }
  })),

  resetUser: () => {

    removeCookie('user');

    return set(() => ({
      user: { name: '', lastName: '', avatar: '', token: '', darkMode: false, role: '', id: '' }
    }));

  }
}));

function useUserState() {

  const user = userStore((state) => state.user);

  const isUserAuthenticated = async () => {

    if (user.token === '') return false;

    await axios
      .get(`${config.get('PHANTOM_BASE_URL')}/user/validateToken/`, { headers: { token: user.token } })
      .then(({ data }) => data.success)
      .catch(() => false);

    return true;

  };

  const updateDarkMode = userStore((state) => state.updateDarkMode);

  const setUser = userStore((state) => state.setUser);

  const updateUser = userStore((state) => state.updateUser);

  const updateToken = userStore((state) => state.updateToken);

  const resetUser = userStore((state) => state.resetUser);

  const getUser = () => ({
    name: user.name, email: user.email, lastName: user.lastName, avatar: user.avatar, token: user.token, darkMode: user.darkMode, role: user.role, id: user.id
  });

  // Used for refreshing user info on page load; in case role or any other user info has changed
  const refreshUser = async () => {

    const freshUser = await axios
      .get(`${config.get('PHANTOM_BASE_URL')}/user/validateToken/`, { headers: { token: user.token } })
      .then(({ data }) => data.user);

    await setUser({ name: freshUser.first_name,
      email: freshUser.email,
      lastName: freshUser.last_name,
      avatar: freshUser.image,
      token: user.token,
      darkMode: freshUser.darkMode || false,
      role: freshUser.role,
      id: freshUser._id });

    return freshUser;

  };

  return {
    getUser,
    isUserAuthenticated,
    setUser,
    resetUser,
    updateUser,
    updateToken,
    updateDarkMode,
    refreshUser
  };

}

export { useAccountStore, useSettingStore, userStore, useManagementStore, useUserState, useImportDrawerStore };
