import { React, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, DatePicker, Dropdown, Select, Space, Switch, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';

import { DownOutlined, SwapOutlined, UnlockOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';

import TagIcon from '../../../../resources/images/Tag.svg';
import TickerIcon from '../../../../resources/images/Ticker.svg';
import { useAccountStore, useManagementStore, useSettingStore, useUserState } from '../../../../store';
import axios from '../../../../utilities/axios';
import { SUMMARY } from '../../../../utilities/functions';

import './styles.scss';

const { RangePicker } = DatePicker;

const assetOptions = [
  {
    label: 'Stocks',
    value: 'Stock'
  },
  {
    label: 'Options',
    value: 'Option'
  },
  {
    label: 'Future',
    value: 'Future'
  },
  {
    label: 'Cash',
    value: 'Cash'
  }
];

const basicMeasureOptions = [
  {
    key: 'Dollar',
    label: 'Dollar',
    value: 'Dollar',
    icon: <span style={{ fontSize: 16 }}>$</span>
  },
  {
    key: 'R-multiple',
    label: 'R-multiple',
    value: 'R-multiple',
    icon: <span style={{ fontSize: 16 }}>R</span>
  }
];

const rangePresets = [
  {
    label: 'Last 7 Days',
    value: [dayjs().add(-7, 'd'), dayjs()]
  },
  {
    label: 'Last 30 Days',
    value: [dayjs().add(-30, 'd'), dayjs()]
  },
  {
    label: 'Last 3 Months',
    value: [dayjs().add(-3, 'M'), dayjs()]
  },
  {
    label: 'Last 6 Months',
    value: [dayjs().add(-6, 'M'), dayjs()]
  },
  {
    label: 'Last 12 Months',
    value: [dayjs().add(-12, 'M'), dayjs()]
  },
  {
    label: 'Last 5 Years',
    value: [dayjs().add(-5, 'y'), dayjs()]
  }
];

const fetchTags = (user, viewAs) => {

  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);

  const results = axios
    .get('crud/tag', { headers: { token: isViewAsActive ? viewAs.newUser.token : user?.token } })
    .then(({ data }) => data)
    .catch((error) => {

      if (error.response.status === 404) {

        return [];

      }
      return error;

    });

  return results;

};

const fetchAllSymbolsTraded = async (user, viewAs) => {

  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);

  const results = await axios
    .get('crud/symbols', { headers: { token: isViewAsActive ? viewAs.newUser.token : user?.token } })
    .then(({ data }) => data)
    .catch((error) => {

      if (error.response.status === 404) {

        return [];

      }
      return error;

    });

  return results;

};

export default function SubHeader() {

  const { allAccounts, changeAccount } = useAccountStore((state) => state);
  const { getUser } = useUserState();
  const { viewAs } = useManagementStore((state) => state);
  const { filter, changeFilter } = useSettingStore((state) => state);
  const onChangeAssetType = (checkedValues) => changeFilter('assetType', checkedValues);
  const onChangeAccounts = (checkedValues) => changeFilter('accounts', checkedValues);
  const onChangeTags = (checkedValues) => changeFilter('tags', checkedValues);
  const onChangeShowAllTags = (value) => changeFilter('showAllTags', value);
  const onChangeSymbols = (checkedValues) => changeFilter('symbols', checkedValues);
  const onChangeStatus = (checkedValues) => changeFilter('status', checkedValues);
  const onChangeSide = (checkedValues) => changeFilter('side', checkedValues);
  const onChangeRange = (rangeArray) => changeFilter('dateRange', rangeArray);
  const onChangeBasicMeasure = (checkedValues) => changeFilter('basic_measure', checkedValues);
  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);
  const [lastUploadDate, setLastUploadDate] = useState();
  const [userAccounts, setUserAccounts] = useState([]);
  const [tempSelectedTags, setTempSelectedTags] = useState([]);
  const [tempShowAllTags, setTempShowAllTags] = useState(false);
  const [tempSelectedSymbols, setTempSelectedSymbols] = useState([]);

  const modifiedFilter = { ...filter, dateRange: null, symbols: [], getAllofTickers: true };

  const { data: summary, isFetched } = useQuery(
    ['summary-account', modifiedFilter],
    () => SUMMARY.fetchSummary(modifiedFilter)
  );

  useEffect(() => {

    if (summary) {

      const lastTradeDay = dayjs(summary.lastTradeDay);
      const firstTradeDay = dayjs(summary.firstTradeDay);
      const cachedLastTradeDay = localStorage.getItem('last-trade-day');
      const cachedFirstTradeDay = localStorage.getItem('first-trade-day');

      if ((firstTradeDay !== cachedFirstTradeDay) || (lastTradeDay !== cachedLastTradeDay)) {

        changeFilter('dateRange', [firstTradeDay, lastTradeDay]);
        localStorage.setItem('last-trade-day', lastTradeDay);
        localStorage.setItem('first-trade-day', firstTradeDay);

      }

    } else if (isFetched) {

      changeFilter('dateRange', null);
      localStorage.removeItem('last-trade-day');
      localStorage.removeItem('first-trade-day');

    }

  }, [summary]);

  const user = getUser();

  const sides = ['Long', 'Short'];
  const statuses = ['Open', 'Closed'];

  const { data: tags = [] } = useQuery(['tags', viewAs], () => fetchTags(user, viewAs));

  const { data: allSymbolsTraded = [] } = useQuery(['allSymbolsTraded', viewAs], () => fetchAllSymbolsTraded(user, viewAs));
  const uniqueSymbols = useMemo(() => [...new Set(allSymbolsTraded)].sort(), [allSymbolsTraded]);

  useEffect(() => {

    setTempSelectedTags(filter.tags);
    setTempShowAllTags(filter.showAllTags);

  }, [filter.tags, filter.showAllTags]);

  const tagMenu = (
      <div className='tag-menu'>
          <div className='tag-menu__switch-container'>
              <span className='tag-menu__switch-label'>Only show results that contain all selected tags</span>
              <Switch
                  checked={tempShowAllTags}
                  onChange={(checked) => setTempShowAllTags(checked)}
                  size='small'
            />
          </div>
          <div className='tag-menu__content'>
              {tags.length > 0 ? (
                Object.entries(
                  tags.reduce((acc, tag) => {

                    const category = tag.categoryLabel || 'Uncategorized';
                    if (!acc[category]) acc[category] = [];
                    acc[category].push(tag);
                    return acc;

                  }, {})
                ).map(([category, categoryTags]) => (
                    <div key={category} className='tag-menu__category'>
                        <h4 className='tag-menu__category-title'>{category}</h4>
                        {categoryTags.map((tag) => (
                            <div key={tag._id} className='tag-menu__checkbox-container'>
                                <Checkbox
                                    checked={tempSelectedTags.includes(tag._id)}
                                    onChange={(e) => {

                                      setTempSelectedTags((prev) => {

                                        const newTags = e.target.checked
                                          ? [...prev, tag._id]
                                          : prev.filter((t) => t !== tag._id);
                                        return newTags;

                                      });

                                    }}
                                >
                                    {tag.label}
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                ))
              ) : (
                  <div className='tag-menu__no-tags'>No available tags.</div>
              )}
          </div>
          <div className='tag-menu__button-container'>
              <Button
                  style={{ color: '#2962FF' }}
                  onClick={() => {

                    setTempSelectedTags([]);
                    setTempShowAllTags(false);

                  }}>Reset</Button>
              <Button
                  type='primary'
                  onClick={() => {

                    onChangeTags(tempSelectedTags);
                    onChangeShowAllTags(tempShowAllTags);
                    // Close the dropdown here if needed

                  }}
            >
                  Apply
              </Button>
          </div>
      </div>
  );

  const symbolMenu = (
      <div className='symbol-menu'>
          <div className='symbol-menu__select-all-container'>
              <Checkbox
                  indeterminate={tempSelectedSymbols.length > 0 && tempSelectedSymbols.length < uniqueSymbols.length}
                  checked={uniqueSymbols.length > 0 && tempSelectedSymbols.length === uniqueSymbols.length}
                  onChange={() => {

                    if (tempSelectedSymbols.length === 0) {

                      setTempSelectedSymbols([...uniqueSymbols]);

                    } else {

                      setTempSelectedSymbols([]);

                    }

                  }}
                  disabled={uniqueSymbols.length === 0}
              >
                  {tempSelectedSymbols.length > 0 && tempSelectedSymbols.length < uniqueSymbols.length ? 'Deselect all' : 'Select all'}
              </Checkbox>
          </div>
          <div className='symbol-menu__content'>
              {uniqueSymbols.length > 0 ? (
                uniqueSymbols.map((symbol) => (
                    <div key={symbol} className='symbol-menu__checkbox-container'>
                        <Checkbox
                            checked={tempSelectedSymbols.includes(symbol)}
                            onChange={(e) => {

                              setTempSelectedSymbols((prev) => {

                                const newSymbols = e.target.checked
                                  ? [...prev, symbol]
                                  : prev.filter((s) => s !== symbol);
                                return newSymbols;

                              });

                            }}
                        >
                            {symbol}
                        </Checkbox>
                    </div>
                ))
              ) : (
                  <div className='symbol-menu__no-tickers'>No available tickers.</div>
              )}
          </div>
          <div className='symbol-menu__button-container'>
              <Button
                  type='primary'
                  onClick={() => {

                    onChangeSymbols(tempSelectedSymbols);
                    // Close the dropdown here if needed

                  }}
                  disabled={uniqueSymbols.length === 0}
              >
                  Apply
              </Button>
          </div>
      </div>
  );

  // Checking all accounts in case it's first access and store doesn't contain any filter changes
  useEffect(() => {

    if (allAccounts.length === 0) {

      onChangeAccounts([]); // Make sure filter is empty if no accounts
      onChangeRange(null);

    } else if (Object.keys(filter.accounts).length === 0 && allAccounts.length > 0) {

      const liveTradeAccount = allAccounts?.filter((account) => account.label === 'Live Trade');
      if (isViewAsActive === false) {

        onChangeAccounts([allAccounts[0].key]);

      } else if (liveTradeAccount.length > 0) {

        onChangeAccounts([liveTradeAccount[0].key]);

      }

      onChangeAccounts([]);

    }

    if (allAccounts.length > 0) {

      const accounts = allAccounts?.filter((account) => {

        if (isViewAsActive === false || (isViewAsActive === true && account.label === 'Live Trade')) {

          return account;

        }

        return null;

      });

      const uploadDates = accounts.map((account) => new Date(account.executionsLogs[account.executionsLogs.length - 1]?.uploadTime));

      if (uploadDates.length > 0) {

        const lastUpload = dayjs(Math.max(...uploadDates)).format('MMM DD, YYYY HH:mm A');
        setLastUploadDate(lastUpload);

      }

      changeFilter('accounts', accounts.map((a) => a.key));
      changeAccount(accounts[0]);
      setUserAccounts(accounts);

    }

    // eslint-disable-next-line padded-blocks
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAccounts]);

  const accountsOptions = userAccounts.map((i) => ({
    label: i.label,
    value: i.key
  })) || [];

  const handleTagClose = (e, tag) => {

    e.preventDefault();
    onChangeTags(filter.tags.filter((t) => t !== tag._id));

  };

  useEffect(() => {

    if (uniqueSymbols.length > 0) {

      setTempSelectedSymbols(uniqueSymbols);
      onChangeSymbols(uniqueSymbols); // Add this line to update the filter on first load

    } else {

      setTempSelectedSymbols([]);

    }

  }, [uniqueSymbols]);

  return (
      <>
          <div className='subheader-container'>
              <Space direction='horizontal' className='subheader-items' align='center' wrap>
                  {userAccounts.length > 0 ? (
                      <>
                          <Space className='first-filter-row' wrap={true}>
                              <Tooltip title='Change Measurement' key='measure'>
                                  <Dropdown
                                      menu={{
                                        items: basicMeasureOptions,
                                        selectable: true,
                                        defaultSelectedKeys: [filter.basic_measure || 'Dollar'],
                                        onClick: ({ key }) => onChangeBasicMeasure(key)
                                      }}
                  >
                                      <Button icon={basicMeasureOptions.filter((option) => option.key === filter.basic_measure)[0].icon} />
                                  </Dropdown>
                              </Tooltip>
                              <span className='prefix-icon tt-calendar'></span>
                              {filter.dateRange ?
                                  <RangePicker
                                      className='custom-range-picker'
                                      onChange={onChangeRange}
                                      value={[dayjs(filter.dateRange[0]), dayjs(filter.dateRange[1])]}
                                      presets={rangePresets}
                                      suffixIcon={false} />
                                :
                                  <RangePicker
                                      className='custom-range-picker'
                                      onChange={onChangeRange}
                                      value={[null, null]}
                                      presets={rangePresets}
                                      suffixIcon={false} />
                }
                              <span className='prefix-icon tt-account_balance'></span>
                              <Select
                                  mode='multiple'
                                  style={{ width: 280 }}
                                  onChange={onChangeAccounts}
                                  options={accountsOptions}
                                  value={filter.accounts}
                                  maxTagCount={1}
                                  showSearch={false}
                                  tagRender={(tag) => (
                                      <span className={tag.value && filter.accounts.indexOf(tag.value) < 1 ? 'custom-select-input' : ''}>
                                          {tag.label}
                                          {tag.value && filter.accounts.indexOf(tag.value) < filter.accounts.length - 1 ? ',\u00A0' : ''}
                                      </span>
                                  )}
                />
                              <span className='prefix-icon tt-account_balance_wallet'></span>
                              <Select
                                  mode='multiple'
                                  defaultValue={filter.assetType}
                                  style={{ width: 280 }}
                                  onChange={onChangeAssetType}
                                  options={assetOptions}
                                  value={filter.assetType}
                                  maxTagCount={2}
                                  showSearch={false}
                                  tagRender={(tag) => (
                                      <span className={tag.value && filter.assetType.indexOf(tag.value) < 1 ? 'custom-select-input' : ''}>
                                          {tag.label}
                                          {tag.value && filter.assetType.indexOf(tag.value) < filter.assetType.length - 1 ? ',\u00A0' : ''}
                                      </span>
                                  )}
                />
                              <span className='prefix-icon'>
                                  <img src={TagIcon} alt='Tag icon' />
                              </span>
                              <Dropdown
                                  overlay={tagMenu}
                                  trigger={['click']}
                                  placement='bottomLeft'
                              >
                                  <Button className='tag-button'>
                                      <div className='tag-button__content'>
                                          <span>Tags</span>
                                          {filter.tags.length > 0 && (
                                          <span className='tag-button__count'>
                                              {filter.tags.length}
                                          </span>
                                          )}
                                      </div>
                                      <DownOutlined className='tag-button__arrow' />
                                  </Button>
                              </Dropdown>
                              {lastUploadDate &&
                              <Space>
                                  <span className='tt-autorenew'></span>
                                  <span>Last upload on {lastUploadDate}</span>
                              </Space>}
                          </Space>
                          <Space wrap={true}>
                              <span className='prefix-icon'>
                                  <img src={TickerIcon} alt='Tickers icon' />
                              </span>
                              <Dropdown
                                  overlay={symbolMenu}
                                  trigger={['click']}
                                  placement='bottomLeft'
                              >
                                  <Button className='symbol-button'>
                                      <div className='symbol-button__content'>
                                          <span>Tickers</span>
                                          {filter.symbols.length > 0 && (
                                          <span className='symbol-button__count'>
                                              {filter.symbols.length}
                                          </span>
                                          )}
                                      </div>
                                      <DownOutlined className='symbol-button__arrow' />
                                  </Button>
                              </Dropdown>
                              <span className='prefix-icon'><SwapOutlined /></span>
                              <Select
                                  mode='multiple'
                                  style={{ width: 280 }}
                                  onChange={onChangeSide}
                                  placeholder='Side'
                                  options={sides.map((item) => ({ label: item, value: item, key: item }))}
                                  value={filter.side}
                                  maxTagCount={1}
                                  showSearch={false}
                                  tagRender={(tag) => (
                                      <span className={filter.tags.indexOf(tag.value) < 1 ? 'custom-select-input' : ''}>
                                          {tag.label}
                                          {filter.tags.indexOf(tag.value) < filter.tags.length - 1 ? ',\u00A0' : ''}
                                      </span>
                                  )}
                />
                              <span className='prefix-icon'><UnlockOutlined /></span>
                              <Select
                                  mode='multiple'
                                  style={{ width: 280 }}
                                  onChange={onChangeStatus}
                                  placeholder='Status'
                                  options={statuses.map((item) => ({ label: item, value: item.toLowerCase(), key: item }))}
                                  value={filter.status}
                                  maxTagCount={1}
                                  showSearch={false}
                                  tagRender={(tag) => (
                                      <span className={filter.tags.indexOf(tag.value) < 1 ? 'custom-select-input' : ''}>
                                          {tag.label}
                                          {filter.tags.indexOf(tag.value) < filter.tags.length - 1 ? ',\u00A0' : ''}
                                      </span>
                                  )}
                />
                          </Space>
                      </>
                  ) : (
                      <></>
                  )}
              </Space>
              {userAccounts.length > 0 ?
                  <Space className='subheader-tags'>
                      {tags.map((tag) => (
                        filter.tags.includes(tag._id) &&
                        <Tag
                            className='tag-item'
                            closable
                            onClose={(e) => handleTagClose(e, tag)}
                            key={tag._id}>
                            {tag.label}
                        </Tag>
                      ))}
                  </Space> : <></>}
          </div>
      </>
  );

}
