import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDebounceCallback } from 'usehooks-ts';

import { useQuery } from '@tanstack/react-query';

import { useSettingStore, useUserState } from '../../../store';
import axios from '../../../utilities/axios';
import { widget } from '../charting_library/charting_library.esm';

import Datafeed from './datafeed';

import './styles.scss';

function serialize(key, obj) {

  // If obj is a Map, convert it to an array of key-value pairs
  if (key === 'groups' || key === 'sources') {

    return {
      _type: 'map',
      data: Array.from(obj.entries())
    };

  }

  return obj;

}

function deserialize(data) {

  // If data represents a Map, convert it back to a Map
  // eslint-disable-next-line no-underscore-dangle
  if (data && data._type === 'map') {

    return new Map(data.data);

  }
  return data;

}

const getDrawings = (id) => axios.get(`crud/trades/drawings/${id}`);

const updateDrawings = (drawings, id) => {

  axios.put(`crud/trades/drawings/${id}`, { drawings });

};

const getStudies = (user) => {

  const results = axios
    .get('crud/studies', { headers: { token: user?.token } })
    .then(({ data }) => data)
    .catch((error) => {

      if (error.response.status === 404) {

        return [];

      }
      return error;

    });

  return results;

};

const addStudies = (user, studies) => {

  axios.post('crud/studies', { studies }, { headers: { token: user?.token } }).then((data) => data.data);

};

const TVChartContainer = ({ symbol, startDate, endDate, timezone }) => {

  const { marks } = useSettingStore((state) => state);
  const chartContainerRef = useRef();
  const tradeDuration = endDate - startDate;
  const { id } = useParams();
  const { getUser } = useUserState();
  const user = getUser();

  const debouncedUpdateDrawings = useDebounceCallback(updateDrawings, 300);
  const debouncedAddStudies = useDebounceCallback(addStudies, 300);

  const { data: drawingsData = [] } = useQuery(['drawings', id], () => getDrawings(id), { enabled: !!id });
  const { data: studiesData = [] } = useQuery(['studies'], () => getStudies(user), { enabled: !!user });

  let interval = '1';

  if (tradeDuration < 24 * 60 * 60) {

    interval = '1';

  } else if (tradeDuration >= 24 * 60 * 60 && tradeDuration < 2 * 24 * 60 * 60) {

    interval = '5';

  } else if (tradeDuration >= 2 * 24 * 60 * 60 && tradeDuration < 5 * 24 * 60 * 60) {

    interval = '15';

  } else if (tradeDuration >= 5 * 24 * 60 * 60 && tradeDuration <= 10 * 24 * 60 * 60) {

    interval = '60';

  } else {

    interval = '1D';

  }

  const fromDate = startDate - (tradeDuration > 7200 ? tradeDuration * 0.25 : 3600);
  const toDate = endDate + (tradeDuration > 7200 ? tradeDuration * 0.25 : 3600);

  const defaultProps = {
    symbol,
    interval,
    timeframe: { from: fromDate, to: toDate },
    datafeedUrl: 'https://demo_feed.tradingview.com',
    libraryPath: '/charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {}
  };

  const saveStudies = async (tvWidget) => {

    try {

      const allStudies = await tvWidget.activeChart().getAllStudies();

      if (allStudies.length === 0) {

        await debouncedAddStudies(user, []);
        return;

      }

      const newStudies = [];

      allStudies.forEach((study) => {

        const studyInput = tvWidget.activeChart().getStudyById(study.id).getInputValues();
        const studyStyle = tvWidget.activeChart().getStudyById(study.id).getStyleValues();
        const studyVisible = tvWidget.activeChart().getStudyById(study.id).isVisible();

        newStudies.push({ name: study.name, inputs: studyInput, style: studyStyle, isVisible: studyVisible });

      });

      await debouncedAddStudies(user, newStudies);

    } catch (error) {

      // During unsubscribe (when switching tabs) a TypeError is thrown, the following is to ignore it.
      if (error.toString().includes('TypeError: Cannot read properties of null')) return;
      console.log(error);

    }

  };

  const debouncedSaveStudies = useDebounceCallback(saveStudies, 300);

  useEffect(() => {

    const widgetOptions = {
      symbol: defaultProps.symbol,
      timeframe: defaultProps.timeframe,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: Datafeed, // new window.Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
      interval: defaultProps.interval,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath,
      locale: 'en',
      disabled_features: [
        'use_localstorage_for_settings',
        'items_favoriting',
        'header_symbol_search',
        'symbol_search_hot_key',
        'header_compare',
        'header_saveload',
        'timeframes_toolbar'
      ],
      enabled_features: ['study_templates', 'saveload_separate_drawings_storage'],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      debug: false,
      overrides: {
        'mainSeriesProperties.candleStyle.upColor': '#fff',
        'mainSeriesProperties.candleStyle.borderUpColor': '#000',
        'mainSeriesProperties.candleStyle.wickUpColor': '#000',
        'mainSeriesProperties.candleStyle.borderDownColor': '#000',
        'mainSeriesProperties.candleStyle.wickDownColor': '#000',
        'mainSeriesProperties.sessionId': 'extended'
      }
    };

    // eslint-disable-next-line new-cap
    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(async () => {

      if (drawingsData.data) {

        const drawings = JSON.parse(drawingsData.data, (key, value) => deserialize(value));
        await tvWidget.activeChart().applyLineToolsState(drawings);

      }

      if (studiesData.length > 0) {

        studiesData[0].studies.forEach(async (study) => {

          const inputValues = study.inputs.map((input) => input.value);
          const newStudyID = await tvWidget.activeChart().createStudy(
            study.name,
            false,
            false,
            inputValues,
            study.style
          );

          tvWidget.activeChart().getStudyById(newStudyID).setVisible(study.isVisible); // set visibility

        });

      }

      tvWidget.applyOverrides({ timezone });

      marks.forEach((mark) => {

        // tvWidget.activeChart().createExecutionShape()
        // .setText(mark.text)
        // .setTooltip(mark.text)
        // .setTextColor(mark.textColor)
        // .setArrowColor(mark.color)
        // .setArrowHeight(10)
        // .setDirection(mark.side)
        // .setTime(mark.time)
        // .setPrice(mark.price);

        tvWidget.activeChart().createShape(
          { time: mark.time, price: mark.price },
          {
            shape: mark.shape,
            lock: true,
            disableSelection: true,
            disableSave: true,
            disableUndo: true,
            text: mark.text,
            zOrder: 'top',
            overrides: mark.overrides
          }
        );

      });

      // tvWidget.headerReady().then(() => {
      // const button = tvWidget.createButton();
      // button.setAttribute('title', 'Click to show a notification popup');
      // button.classList.add('apply-common-tooltip');
      // button.addEventListener('click', () => tvWidget.showNoticeDialog({
      //     title: 'Notification',
      //  body: 'TradingView Charting Library API works correctly',
      //    callback: () => {
      //    console.log('Noticed!');
      //    },
      //  }));

      //  button.innerHTML = 'Check API';
      // });

      tvWidget.subscribe('drawing_event', async (eventId, type) => {

        if (['remove', 'properties_changed', 'create', 'move', 'hide', 'show', 'points_changed'].includes(type)) {

          const allDrawings = tvWidget.activeChart().getLineToolsState();
          await debouncedUpdateDrawings(JSON.stringify(allDrawings, (key, value) => serialize(key, value)), id);

        }

      });

      tvWidget.subscribe('study_event', async () => {

        debouncedSaveStudies(tvWidget);

      });

      tvWidget.subscribe('study_properties_changed', async () => {

        debouncedSaveStudies(tvWidget);

      });

    });

    return () => {

      tvWidget.remove();

    };

  });

  return <div ref={chartContainerRef} className={'TVChartContainer'} />;

};

TVChartContainer.propTypes = {

  symbol: PropTypes.string.isRequired,
  startDate: PropTypes.number.isRequired,
  endDate: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired

};

export default React.memo(TVChartContainer);
