import React from 'react';
import { Button, Card, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import DailyPnLLineChart from '../../../components/visualizations/daily-pnl-line-chart';
import { useManagementStore } from '../../../store';
import { FORMAT } from '../../../utilities/functions';

// const { Text } = Typography;

export default function DayNoteTradeCard({ dailyTradeNote, isInList,
  setIsModalOpen, setSelectDayTrade }) {

  const { viewAs } = useManagementStore((state) => state);

  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);

  const getClassName = (pl) => {

    if (pl > 0) {

      return 'positive';

    }
    if (pl < 0) {

      return 'negative';

    }
    return '';

  };

  const sorter = (a, b) => {

    if (typeof a === 'string' && typeof b === 'string') {

      return a.localeCompare(b);

    } if (Number.isNaN(a) || Number.isNaN(b)) {

      return (a || '').localeCompare(b || '');

    }
    return a - b;

  };

  const columns = [
    {
      title: 'Status',
      dataIndex: '',
      key: '',
      render: (e, i) => {

        let color;
        if (e.status === 'open') {

          color = 'blue';

        } else if (e.status === 'closed') {

          color = '';

        } else {

          color = '#E57373';

        }
        return (
            <Tag color={color} key={`${e.status}_${i}`}>
                {e.status.toUpperCase()}
            </Tag>
        );

      }
    },
    {
      title: 'Asset Type',
      dataIndex: 'assetType',
      key: 'assetType'
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      sorter: (a, b) => sorter(a.symbol, b.symbol)
    },
    {
      title: 'Side',
      dataIndex: 'side',
      key: 'side'
    },
    {
      title: 'Open Time',
      dataIndex: 'openTime',
      key: 'openTime',
      render: (openTime) => {

        if (openTime && openTime !== '') {

          return dayjs(openTime).format('YYYY-MM-DD HH:mm:ss');

        }

        return '';

      },
      sorter: (a, b) => sorter(a.openTime, b.openTime)
    },
    {
      title: 'Close Time',
      dataIndex: 'closeTime',
      key: 'closeTime',
      render: (closeTime) => {

        if (closeTime && closeTime !== '') {

          return dayjs(closeTime).format('YYYY-MM-DD HH:mm:ss');

        }

        return '';

      },
      sorter: (a, b) => sorter(a.closeTime, b.closeTime)
    },
    {
      title: 'Open Price',
      dataIndex: 'openPrice',
      key: 'openPrice',
      render: (openPrice) => FORMAT.NUMBER_TO_CURRENCY(openPrice),
      sorter: (a, b) => sorter(FORMAT.CURRENCY_TO_NUMBER(a.openPrice), FORMAT.CURRENCY_TO_NUMBER(b.openPrice))
    },
    {
      title: 'Close Price',
      dataIndex: 'closePrice',
      key: 'closePrice',
      render: (closePrice) => FORMAT.NUMBER_TO_CURRENCY(closePrice),
      sorter: (a, b) => sorter(FORMAT.CURRENCY_TO_NUMBER(a.closePrice), FORMAT.CURRENCY_TO_NUMBER(b.closePrice))

    },
    {
      title: 'Total Volume',
      dataIndex: 'totalVolume',
      key: 'totalVolume',
      render: (totalVolume) => FORMAT.HUMANIZE(totalVolume, (n) => Number(n.toFixed(2))),
      sorter: (a, b) => sorter(a.totalVolume, b.totalVolume)
    },
    {
      title: 'Total Commission',
      dataIndex: 'totalCommission',
      key: 'totalCommission',
      render: (totalCommission) => FORMAT.NUMBER_TO_CURRENCY(totalCommission),
      sorter: (a, b) => sorter(FORMAT.CURRENCY_TO_NUMBER(a.totalCommission), FORMAT.CURRENCY_TO_NUMBER(b.totalCommission))
    },
    {
      title: 'Profit and Loss (P&L)',
      dataIndex: 'netPnL',
      key: 'netPnL',
      sorter: (a, b) => sorter(FORMAT.CURRENCY_TO_NUMBER(a.netPnL), FORMAT.CURRENCY_TO_NUMBER(b.netPnL)),
      render: (netPnL) => {

        let color;
        if (netPnL > 0) {

          color = '#29a373';

        } else if (netPnL < 0) {

          color = '#E57373';

        } else {

          color = 'grey';

        }
        return (
            <Tag color={color} key={netPnL}>
                {FORMAT.NUMBER_TO_CURRENCY(netPnL)}
            </Tag>
        );

      }
    }
  ];

  const executionColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol'
    },
    {
      title: 'Side',
      dataIndex: 'side',
      key: 'side'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => FORMAT.NUMBER_TO_CURRENCY(price)
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission',
      render: (commission) => FORMAT.NUMBER_TO_CURRENCY(commission)
    },
    {
      title: 'Gross Realized P&L',
      dataIndex: 'realizedGain',
      key: 'realizedGain',
      render: (PnL) => {

        let color;
        if (PnL > 0) {

          color = '#29a373';

        } else if (PnL < 0) {

          color = '#E57373';

        } else {

          color = 'grey';

        }
        return (
            <Tag color={color} key={PnL}>
                {FORMAT.NUMBER_TO_CURRENCY(PnL)}
            </Tag>
        );

      }
    },
    {
      title: 'Net Realized P&L',
      dataIndex: 'netRealizedGain',
      key: 'netRealizedGain',
      render: (PnL) => {

        let color;
        if (PnL > 0) {

          color = '#29a373';

        } else if (PnL < 0) {

          color = '#E57373';

        } else {

          color = 'grey';

        }
        return (
            <Tag color={color} key={PnL}>
                {FORMAT.NUMBER_TO_CURRENCY(PnL)}
            </Tag>
        );

      }
    },
    {
      title: 'Open Position',
      dataIndex: 'remainingShares',
      key: 'remainingShares'
    }
  ];

  return (
      <>
          {dailyTradeNote &&
          <Card
              title={<span className='openTrade'>
                  {dailyTradeNote.dateFormat}
              </span>}
              extra={isInList === true && isViewAsActive === false && <Button
                  className='submit-btn'
                  type='default'
                  size='middle'
                  onClick={() => {

                    setIsModalOpen(true);
                    setSelectDayTrade(dailyTradeNote);

                  }} >Manage</Button>}
              className='dayNoteCard'
              bordered={false}
        >
              <div className='headerDetails'>
                  <div className='info'>
                      <div className='pl'>
                          <div>
                              <span><i className='tt-Wallet'></i> Net P&L</span>
                              <span className={`number ${getClassName(dailyTradeNote.totalNetPnL)}`}>
                                  {FORMAT.NUMBER_TO_CURRENCY(dailyTradeNote.totalNetPnL)}
                              </span>
                          </div>
                          <div>
                              <span><i className='tt-Trade'></i> Total Trades</span>
                              <span className='number'>{dailyTradeNote.totalTrades}</span>
                          </div>
                      </div>
                      <div className='commission'>
                          <div>
                              <span><i className='tt-Dividends'></i> Commissions</span>
                              <span className='number'>${dailyTradeNote.totalCommission}</span>
                          </div>
                          {/* <div>
                  <span><i className='tt-Profile'></i> Profit Factor</span>
                  <span className='number'>{dailyTradeNote.profitFactor}</span>
                </div> */}
                          <div>
                              <span><i className='tt-Bar'></i> Volume</span>
                              <span className='number'>{dailyTradeNote.totalVolume}</span>
                          </div>
                      </div>
                  </div>
                  <div className='chartSection'>
                      <div className='winLoss'>
                          <div>
                              <span>Winners</span>
                              <span className='number'>
                                  {dailyTradeNote.winners} <i className='tt-Thumbs-Up'></i>
                              </span>
                          </div>
                          <div>
                              <span>Losers</span>
                              <span className='number'>
                                  {dailyTradeNote.losers} <i className='tt-Thumbs-Down'></i>
                              </span>
                          </div>
                      </div>
                      <div className='chart'>
                          <DailyPnLLineChart data={dailyTradeNote.trades} date={dailyTradeNote.date} />
                      </div>
                  </div>
              </div>
              {isInList === true &&
              <div className='dayNoteTrades'>
                  <Table
                      rowKey='id'
                      columns={columns}
                      pagination={{
                        pageSize: 5,
                        hideOnSinglePage: true,
                        size: 'default',
                        position: ['bottomCenter'],
                        showTitle: false,
                        responsive: true,
                        showSizeChanger: false,
                        align: 'end'
                      }}
                      expandable={{
                        expandedRowRender: (record) => (
                            <Table
                                rowKey={(r) => r.date + r.remainingShares}
                                columns={executionColumns}
                                dataSource={record.executions}
                                pagination={false}
                    />
                        ),
                        rowExpandable: (record) => record.symbol !== 'Not Expandable'
                      }}
                      dataSource={dailyTradeNote.trades}
              />
              </div>
          }
          </Card>
      }
      </>
  );

}

DayNoteTradeCard.propTypes = {
  dailyTradeNote: PropTypes.object,
  isInList: PropTypes.bool,
  index: PropTypes.number,
  setIsModalOpen: PropTypes.func,
  setSelectDayTrade: PropTypes.func

};
