import React, { useEffect, useState } from 'react';
import { Button, DatePicker, message, Modal } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { useQueryClient } from '@tanstack/react-query';

import NotesTemplateEditor from '../../../components/notes-editor/components/NotesTemplateEditor';
import { useManagementStore, useUserState } from '../../../store';
import axios from '../../../utilities/axios';
import DayNoteTradeCard from '../day-note-trade-card';

export default function DayLogModalPage({ disabledDates, dailyTradeNote, isModalOpen, setIsModalOpen }) {

  const { getUser } = useUserState();
  const user = getUser();

  const { viewAs } = useManagementStore((state) => state);
  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);

  const queryClient = useQueryClient();

  const [dailyTradeNoteSelected, setDailyTradeNoteSelected] = useState({});
  const [submited, setSubmited] = useState(false);
  const [notesContent, setNotesContent] = useState('');
  const [existingContent, setExistingContent] = useState('');

  const dailyTradeNoteAddorUpdate = () => {

    setSubmited(true);

    const { noteId, date } = dailyTradeNoteSelected;

    if (isViewAsActive === true) {

      message.error('You cannot create a note for other users.');

    }

    if (date) {

      const formattedDate = dayjs(date).format('YYYY-MM-DD');

      const originalDateOnly = dayjs(date).utc().format('YYYY-MM-DD');

      const adjustedDate = formattedDate < originalDateOnly
        ? dayjs(date).add(1, 'day').format('YYYY-MM-DD')
        : formattedDate;

      const body = {
        Id: noteId,
        note: notesContent,
        date: adjustedDate
      };

      axios.post('crud/daily-trade-notes', body, {
        headers: {
          token: user?.token
        }
      }).then(() => {

        queryClient.invalidateQueries(['daily-trade-notes']);
        message.success(!dailyTradeNote ? 'New Daily Trade Note created' : 'Daily Trade Note updated');
        setDailyTradeNoteSelected({});
        setNotesContent('');
        setExistingContent('');

      }).catch((error) => {

        message.error(error.response?.data?.message || 'Oops! Something Went Wrong. Please try again.');

      }).finally(() => {

        setSubmited(false);
        setIsModalOpen(false);

      });

    }

  };

  useEffect(() => {

    if (dailyTradeNote) {

      setDailyTradeNoteSelected(dailyTradeNote);
      setNotesContent(dailyTradeNote.note);
      setExistingContent(dailyTradeNote.note);

    } else {

      setDailyTradeNoteSelected({});
      setNotesContent('');
      setExistingContent('');

    }

  }, [dailyTradeNote]);

  const isDisabledDate = (current) => {

    const dateToCheck = dayjs(current).utc().format('YYYY-MM-DD');

    const normalizedDisabledDates = disabledDates.map((date) => dayjs(date).utc().format('YYYY-MM-DD'));

    return normalizedDisabledDates.includes(dateToCheck);

  };

  const fnCancelButton = () => {

    setIsModalOpen(false);
    setExistingContent('');
    setNotesContent('');

  };

  return (
      <Modal
          className='borderModal'
          title={dailyTradeNote ? 'Manage Day Log' : 'Create a Day Log'}
          open={isModalOpen}
          width={1200}
          footer={
              <div className='modalFooter'>
                  <div>
                  </div>
                  <div>
                      <Button onClick={fnCancelButton}>Cancel</Button>
                      <Button type='primary' onClick={dailyTradeNoteAddorUpdate}>
                          {dailyTradeNote ? 'Save Day Log' : 'Create Day Log'}
                      </Button>
                  </div>
              </div>
      }
          onCancel={() => {

            setIsModalOpen(false);
            setDailyTradeNoteSelected({});
            setNotesContent('');
            setExistingContent('');

          }}
    >
          {dailyTradeNote ? (
              <DayNoteTradeCard
                  dailyTradeNote={dailyTradeNote}
                  index={0}
                  isInList={false}
                  setSelectDayTrade={null}
                  setIsModalOpen={null} />
          ) : (
              <div className='form'>
                  <div className='controller'>
                      <label>
                          <span className='labelStar'>*</span>
                          Date of Trade
                      </label>
                      <DatePicker
                          className={submited && !dailyTradeNoteSelected.date ? 'ant-input-status-error' : ''}
                          disabledDate={isDisabledDate}
                          value={dailyTradeNoteSelected.date ? dayjs(dailyTradeNoteSelected.date) : null}
                          onChange={(newDate) => setDailyTradeNoteSelected((prev) => ({ ...prev, date: newDate }))}
                          picker='date'
            />
                      <span className='helpController'>Please select a date to log your trades.</span>
                      {submited && !dailyTradeNoteSelected.date && (
                      <div>
                          <div id='date_help' className='ant-form-item-explain-connected' role='alert'>
                              <div style={{ color: 'red' }} className='ant-form-item-explain-error'>Please select a Date</div>
                          </div>
                      </div>
                      )}
                  </div>
              </div>
          )}

          <div className='form'>
              <div className='controller'>

                  <NotesTemplateEditor
                      setNotesContent={setNotesContent}
                      existingContent={existingContent}
                      setExistingContent={setExistingContent}
                      titleFunction={fnCancelButton}
          />

              </div>
          </div>
      </Modal>
  );

}

DayLogModalPage.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  dailyTradeNote: PropTypes.object,
  disabledDates: PropTypes.array
};
