import { Chart, registerables } from 'chart.js';
import dayjs from 'dayjs';

Chart.register(...registerables);

const previousClose = 0;

const LineChartVirtualize = {
  colors: () => ({
    green: '#29a373',
    lightGreen: 'rgba(187, 240, 192, 0.5)',
    red: '#e57373',
    bgColor: '#ffffff',
    whiteFade: '#ffffff00',
    neutral40: '#90A4AE',
    rose: 'rgba(229, 115, 115, 0.5)'
  }),

  LineChart: (prices, dates, darkMode = false) => {

    const canvas = document.createElement('CANVAS');

    let chartColor = LineChartVirtualize.colors();
    if (darkMode) {

      chartColor = {
        ...chartColor,
        green: '#006400',
        lightGreen: 'rgba(187, 240, 192, 0.5)',
        red: '#ff6347',
        bgColor: '#333333',
        rose: 'rgba(229, 115, 115, 0.5)'
      };

    }

    const maxDifferent = Math.abs((Math.max(...prices) - 1));
    const minDifferent = Math.abs((1 - Math.min(...prices)));

    const greenGradient = canvas.getContext('2d').createLinearGradient(0, 0, 0, 400);
    greenGradient.addColorStop(0, chartColor.lightGreen);
    greenGradient.addColorStop(
      Number(Math.min(1, Number(Math.max(maxDifferent, 0.15)))),
      chartColor.bgColor
    );

    const redGradient = canvas.getContext('2d').createLinearGradient(0, 0, 0, 500);
    redGradient.addColorStop(0, chartColor.bgColor);
    redGradient.addColorStop(
      Number(Math.min(1, Number(Math.max(minDifferent, 0.2)))),
      chartColor.rose
    );

    // Prepare data
    const data = {
      labels: dates.sort(),
      datasets: [
        {
          label: 'price',
          fill: {
            above: greenGradient,
            below: redGradient,
            target: { value: previousClose }
          },
          lineTension: 0,
          borderJoinStyle: 'round',
          borderCapStyle: 'round',
          backgroundColor: chartColor.bgColor,
          segment: {
            borderColor: '#72649d'
          },
          borderDash: [0, 0],
          borderWidth: 2,
          pointHoverBorderWidth: 0,
          pointHoverBackgroundColor: chartColor.pointerColor,
          data: prices,
          pointRadius: 0,
          normalized: true
        },
        {
          label: 'Previous Close',
          data: Array(dates.length).fill(previousClose),
          borderColor: chartColor.neutral40,
          borderWidth: 2,
          borderDash: [5, 5],
          pointRadius: 0
        }
      ]
    };

    const options = LineChartVirtualize.chartLineModel(dates);

    return {
      data,
      options
    };

  },

  chartLineModel: (dates) => ({
    animation: false,
    maintainAspectRatio: false,
    layout: { padding: { top: 0, left: 0, bottom: 0 } },
    plugins: {
      tooltip: {
        position: 'custom',
        mode: 'index',
        bodyFont: {
          size: 12
        },
        backgroundColor: 'rgba(38, 50, 56, 0.85)',
        intersect: false,
        displayColors: false,
        caretSize: 0,
        callbacks: {
          label(context) {

            const currentPrice = context.parsed.y;

            const currentTime = Number.isNaN(Number(context.label))
              ? context.label
              : Number(context.label);

            return [
              `$${Number(currentPrice).toFixed(2)}`,
              `${dayjs.tz(currentTime).format('HH:mm')}`
            ];

          },
          title() {

            return '';

          }
        }
      },
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        border: {
          display: false
        },
        min: dates[0],
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          display: true,
          autoSkip: true,
          maxTicksLimit: 5,
          color: '#252533',
          callback: (value) => {

            const dt = dates[value];
            return dayjs(dt).format('HH:mm');

          }
        }
      },
      y: {
        display: true,
        grid: {
          display: true
        },
        maxTicksLimit: 5
      }
    },
    hover: { mode: 'nearest', intersect: true }
  })
};

export default LineChartVirtualize;
