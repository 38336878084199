/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { ColorPicker, Select } from 'antd';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $parseSerializedNode,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND
} from 'lexical';

import {
  $isListNode,
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $isHeadingNode
} from '@lexical/rich-text';
import {
  $getSelectionStyleValueForProperty,
  $isParentElementRTL,
  $patchStyleText,
  $setBlocksType
} from '@lexical/selection';
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister
} from '@lexical/utils';

import { getSelectedNode } from '../utils/getSelectedNode';

import { INSERT_COLLAPSIBLE_COMMAND } from './CollapsiblePlugin';

const blockTypeToBlockName = {
  paragraph: 'Normal',
  bullet: 'Bulleted List',
  check: 'Check List',
  number: 'Numbered List'
};

const isJSON = (str) => {

  try {

    JSON.parse(str);

  } catch (e) {

    return false;

  }
  return true;

};

const FONT_FAMILY_OPTIONS = [
  ['Arial', 'Arial'],
  ['Courier New', 'Courier New'],
  ['Georgia', 'Georgia'],
  ['Times New Roman', 'Times New Roman'],
  ['Trebuchet MS', 'Trebuchet MS'],
  ['Verdana', 'Verdana']
];

const FONT_SIZE_OPTIONS = [
  ['10px', '10px'],
  ['11px', '11px'],
  ['12px', '12px'],
  ['13px', '13px'],
  ['14px', '14px'],
  ['15px', '15px'],
  ['16px', '16px'],
  ['17px', '17px'],
  ['18px', '18px'],
  ['19px', '19px'],
  ['20px', '20px']
];

function dropDownActiveClass(active) {

  if (active) {

    return 'active dropdown-item-active';

  }
  return '';

}

function BlockFormatDropDown({
  editor,
  blockType,
  disabled = false
}) {

  const preventEmptyBlock = () => {

    editor.update(() => {

      const root = $getRoot();
      if (root.getChildrenSize() === 0) {

        const paragraph = $createParagraphNode();
        const text = $createTextNode(' ');
        paragraph.append(text);
        root.append(paragraph);

      }

    });

  };

  const formatParagraph = () => {

    editor.update(() => {

      const selection = $getSelection();
      if ($isRangeSelection(selection)) {

        $setBlocksType(selection, () => $createParagraphNode());

      }

    });

  };

  const formatBulletList = () => {

    preventEmptyBlock();

    if (blockType !== 'bullet') {

      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);

    } else {

      formatParagraph();

    }

  };

  const formatCheckList = () => {

    preventEmptyBlock();

    if (blockType !== 'check') {

      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);

    } else {

      formatParagraph();

    }

  };

  const formatNumberedList = () => {

    preventEmptyBlock();

    if (blockType !== 'number') {

      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);

    } else {

      formatParagraph();

    }

  };

  return (
      <>
          <button
              disabled={disabled}
              className={`toolbar-item ${dropDownActiveClass(blockType === 'number')}`}
              onClick={formatNumberedList}>
              <i className='format tt-ordered_list' />
          </button>
          <button
              disabled={disabled}
              className={`toolbar-item item ${dropDownActiveClass(blockType === 'bullet')}`}
              onClick={formatBulletList}>
              <i className='format tt-bullet_list' />
          </button>
          <button
              disabled={disabled}
              className={`toolbar-item item ${dropDownActiveClass(blockType === 'check')}`}
              onClick={formatCheckList}>
              <i style={{ fontSize: '0.875rem', marginTop: '0.25rem' }} className='format tt-checked_list' />
          </button>
      </>
  );

}

function Divider() {

  return <div className='divider' />;

}

function FontDropDown({
  editor,
  value,
  style,
  disabled = false
}) {

  const handleClick = useCallback(
    (option) => {

      editor.update(() => {

        const selection = $getSelection();
        if (selection !== null) {

          $patchStyleText(selection, {
            [style]: option
          });

        }

      });

    },
    [editor, style]
  );

  return (
      <Select
          disabled={disabled}
          style={ style === 'font-size' ? { width: 95 } : { width: 170 }}
          onSelect={(option) => handleClick(option)}
          value={value}
          >
          {(style === 'font-family' ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(
            ([option, text]) => (
                <Select.Option
                    className={`item ${dropDownActiveClass(value === option)} ${
                      style === 'font-size' ? 'fontsize-item' : ''
                    }`}
                    key={option}>
                    <span className='text'>{text}</span>
                </Select.Option>
            )
          )}
      </Select>
  );

}

function ElementFormatDropdown({
  editor,
  value,
  disabled = false
}) {

  return (
      <Select
          disabled={disabled}
          value={value}
          style={{ width: 145 }}
          defaultValue={value}
          onSelect={(option) => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, option)}
          >
          <Select.Option
              key={'left'}
              className='item'>
              <i className='icon tt-paragraph_left' />
              <span className='text'> Left Align</span>
          </Select.Option>
          <Select.Option
              key={'center'}
              className='item'>
              <i className='icon tt-paragraph_center' />
              <span className='text'> Center Align</span>
          </Select.Option>
          <Select.Option
              key={'right'}
              className='item'>
              <i className='icon tt-paragraph_right' />
              <span className='text'> Right Align</span>
          </Select.Option>
          <Select.Option
              key={'justify'}
              className='item'>
              <i className='icon tt-paragraph_justify' />
              <span className='text'> Justify Align</span>
          </Select.Option>
      </Select>
  );

}

export default function ToolbarPlugin({ existingContent, contentToAppend, isTemplateModal = false, isReadOnly = false }) {

  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState('paragraph');
  const [fontSize, setFontSize] = useState('15px');
  const [fontColor, setFontColor] = useState('#000');
  const [fontFamily, setFontFamily] = useState('Arial');
  const [elementFormat, setElementFormat] = useState('left');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isRTL, setIsRTL] = useState(false);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());

  const appendState = (serializedEditorState) => {

    editor.update(() => {

      const root = $getRoot();

      const parsedRoot = serializedEditorState.root;
      const parsedNodes = parsedRoot.children;

      const nodesToInsert = parsedNodes.map($parseSerializedNode);

      nodesToInsert.forEach((node) => {

        root.append(node);

      });

      const newParagraph = $createParagraphNode();
      root.append(newParagraph);

    });

  };

  useEffect(() => {

    if (contentToAppend) {

      appendState(contentToAppend);

      editor.update(() => {

        $getRoot().selectEnd();

      });

    }

  }, [contentToAppend]);

  const $updateToolbar = useCallback(() => {

    const selection = $getSelection();

    if ($isRangeSelection(selection)) {

      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {

            const parent = e.getParent();
            return parent !== null && $isRootOrShadowRoot(parent);

          });

      if (element === null) {

        element = anchorNode.getTopLevelElementOrThrow();

      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsRTL($isParentElementRTL(selection));

      const node = getSelectedNode(selection);
      const parent = node.getParent();

      if (elementDOM !== null) {

        if ($isListNode(element)) {

          const parentList = $getNearestNodeOfType(
            anchorNode,
            ListNode
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);

        } else {

          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {

            setBlockType(type);

          }

        }

      }
      // Handle buttons
      setFontColor(
        $getSelectionStyleValueForProperty(selection, 'color', '#000')
      );

      setFontFamily(
        $getSelectionStyleValueForProperty(selection, 'font-family', 'Arial')
      );
      let matchingParent;

      // If matchingParent is a valid node, pass it's format type
      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
            ? node.getFormatType()
            : parent?.getFormatType() || 'left'
      );

    }
    if ($isRangeSelection(selection)) {

      setFontSize(
        $getSelectionStyleValueForProperty(selection, 'font-size', '15px')
      );

    }

  }, [activeEditor, editor]);

  useEffect(
    () => {

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {

          setActiveEditor(newEditor);
          $updateToolbar();
          return false;

        },
        COMMAND_PRIORITY_CRITICAL
      );

    },
    [editor, $updateToolbar]
  );

  useEffect(() => {

    activeEditor.getEditorState().read(() => {

      $updateToolbar();

    });

  }, [activeEditor, $updateToolbar]);

  useEffect(
    () => {

      activeEditor.setEditable(!isReadOnly);
      setIsEditable(!isReadOnly);

      mergeRegister(
        editor.registerEditableListener((editable) => {

          setIsEditable(editable);

        }),
        activeEditor.registerUpdateListener(({ editorState }) => {

          editorState.read(() => {

            $updateToolbar();

          });

        })
      );

    },

    [$updateToolbar, activeEditor, editor, isReadOnly]
  );

  useEffect(() => {

    activeEditor.update(() => {

      if (contentToAppend && isTemplateModal) {

        $getRoot().clear();

        const parsedState = activeEditor.parseEditorState(contentToAppend);
        if (!parsedState.isEmpty()) {

          activeEditor.setEditorState(parsedState);

        }

      }

    });

  }, [contentToAppend, activeEditor]);

  useEffect(() => {

    activeEditor.update(() => {

      if (existingContent) {

        if (!isJSON(existingContent)) {

          const paragraph = $createParagraphNode();
          const text = $createTextNode(existingContent);
          paragraph.append(text);

          $getRoot()
            .clear()
            .append(paragraph);

        } else {

          const parsedState = activeEditor.parseEditorState(existingContent);
          if (!parsedState.isEmpty()) {

            activeEditor.setEditorState(parsedState);

          }

        }

      } else {

        $getRoot().clear();

      }

    });

  }, [existingContent, activeEditor]);

  const applyStyleText = useCallback(
    (styles, skipHistoryStack) => {

      activeEditor.update(
        () => {

          const selection = $getSelection();
          if (selection !== null) {

            $patchStyleText(selection, styles);

          }

        },
        skipHistoryStack ? { tag: 'historic' } : {}
      );

    },
    [activeEditor]
  );

  const onFontColorSelect = useCallback(
    (value, skipHistoryStack) => {

      applyStyleText({ color: value }, skipHistoryStack);

    },
    [applyStyleText]
  );

  return (
      <div className='toolbar'>
          <>
              <FontDropDown
                  disabled={!isEditable}
                  style={'font-family'}
                  value={fontFamily}
                  editor={activeEditor}
          />
              <Divider />
              <FontDropDown
                  disabled={!isEditable}
                  style={'font-size'}
                  value={fontSize}
                  editor={activeEditor}

                 />
              <Divider />
              <button
                  disabled={!isEditable}
                  onClick={() => {

                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');

                  }}
                  className={`toolbar-item spaced ${isBold ? 'active' : ''}`}
                  title={'Bold (Ctrl+B)'}
                  type='button'
                  aria-label={`Format text as bold. Shortcut: ${
                    'Ctrl+B'
                  }`}>
                  <i className='format tt-bold' />
              </button>
              <button
                  disabled={!isEditable}
                  onClick={() => {

                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');

                  }}
                  className={`toolbar-item spaced ${isItalic ? 'active' : ''}`}
                  title={'Italic (Ctrl+I)'}
                  type='button'
                  aria-label={`Format text as italics. Shortcut: ${
                    'Ctrl+I'
                  }`}>
                  <i className='format tt-italic' />
              </button>
              <button
                  disabled={!isEditable}
                  onClick={() => {

                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');

                  }}
                  className={`toolbar-item spaced ${isUnderline ? 'active' : ''}`}
                  title={'Underline (Ctrl+U)'}
                  type='button'
                  aria-label={`Format text to underlined. Shortcut: ${
                    'Ctrl+U'
                  }`}>
                  <i className='format tt-underline' />
              </button>
              <ColorPicker
                  disabled={!isEditable}
                  value={fontColor}
                  onChange={(value, hex) => onFontColorSelect(hex)}
          />
              <>
                  <Divider />
                  <ElementFormatDropdown
                      disabled={!isEditable}
                      value={elementFormat}
                      editor={activeEditor}
                      isRTL={isRTL}
      />

                  <Divider />
                  {blockType in blockTypeToBlockName && activeEditor === editor && (
                  <>
                      <BlockFormatDropDown
                          disabled={!isEditable}
                          blockType={blockType}
                          // rootType={rootType}
                          editor={activeEditor}
              />
                      <Divider />
                  </>
                  )}
                  <button
                      onClick={() => {

                        activeEditor.dispatchCommand(
                          INSERT_COLLAPSIBLE_COMMAND,
                          undefined
                        );

                      }}
                      className='toolbar-item'>
                      <i className='icon tt-arrow_forward_ios' />
                      <span className='text'>Collapsible</span>
                  </button>
              </>
          </>
      </div>
  );

}
