import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types';

import { ListItemNode, ListNode } from '@lexical/list';
import { MarkNode } from '@lexical/mark';
import { OverflowNode } from '@lexical/overflow';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';

import CollapsiblePlugin from './plugins/CollapsiblePlugin';
import { CollapsibleContainerNode } from './plugins/CollapsiblePlugin/CollapsibleContainerNode';
import { CollapsibleContentNode } from './plugins/CollapsiblePlugin/CollapsibleContentNode';
import { CollapsibleTitleNode } from './plugins/CollapsiblePlugin/CollapsibleTitleNode';
import { MaxLengthPlugin } from './plugins/MaxLengthPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import theme from './theme';

import './styles.scss';

function InitialStatePlugin({ initialState }) {

  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {

    if (initialState) {

      const parsedState = editor.parseEditorState(initialState);
      if (!parsedState.isEmpty()) {

        editor.setEditorState(parsedState);

      }

    }

  }, [editor, initialState]);

  return null;

}

InitialStatePlugin.propTypes = {
  initialState: PropTypes.string
};

export default function NotesEditor({ content, onChange, placeholder, contentToAppend, SubmitButton, CancelButton, isTemplateModal = false, isReadOnly = false }) {

  const editorConfig = {
    namespace: 'Journal Note',
    nodes: [
      ListNode,
      ListItemNode,
      OverflowNode,
      MarkNode,
      CollapsibleContainerNode,
      CollapsibleContentNode,
      CollapsibleTitleNode
    ],
    editable: true,
    onError(error) {

      throw error;

    },
    theme
  };

  const handleEditorChange = (editorState) => {

    const json = editorState.toJSON();

    if (json?.root.direction) {

      onChange(JSON.stringify(json));

    } else {

      onChange('');

    }

  };

  return (
      <Card className='notes-editor'>
          <LexicalComposer initialConfig={editorConfig}>
              <div className='editor-container'>
                  <ToolbarPlugin
                      existingContent={content}
                      contentToAppend={contentToAppend}
                      isReadOnly={isReadOnly}
                      isTemplateModal={isTemplateModal}
              />
                  <div className='editor-inner'>
                      <RichTextPlugin
                          contentEditable={
                              <ContentEditable
                                  className='editor-input'
                                  aria-placeholder={placeholder}
                                  onChange={handleEditorChange}
                                  placeholder={
                                      <div className='editor-placeholder'>{placeholder}</div>
                }
              />
            }
                          ErrorBoundary={LexicalErrorBoundary}
          />
                      <ListPlugin />
                      <CheckListPlugin />
                      <MaxLengthPlugin maxLength={2000} />
                      <HistoryPlugin />
                      <AutoFocusPlugin />
                      <OnChangePlugin onChange={handleEditorChange}/>
                      <CollapsiblePlugin />
                  </div>
              </div>
          </LexicalComposer>
          {SubmitButton && <div className='editor-submit'>
              <CancelButton />
              <SubmitButton />
          </div>}
      </Card>
  );

}

NotesEditor.propTypes = {

  onChange: PropTypes.func,
  content: PropTypes.any,
  placeholder: PropTypes.string,
  contentToAppend: PropTypes.any,
  SubmitButton: PropTypes.any,
  CancelButton: PropTypes.any,
  isTemplateModal: PropTypes.bool,
  selectedTemplate: PropTypes.string,
  isReadOnly: PropTypes.bool

};
