import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';

import AreaLayer from './layers/AreaLayer.jsx';
import LineLayer from './layers/LineLayer.jsx';
import ZeroLineLayer from './layers/ZeroLineLayer.jsx';

const RunningPnLLineChart = ({ data, sparkLineMode = false }) => {

  const [labelFormat, setLabelFormat] = useState({ tickValues: 'every 1 days', format: '%b %Y' });

  useEffect(() => {

    // Logic to generate different x-axis label formats based on the number of data points of the chart
    if (data && data.length > 0) {

      // Checking number of data points (days) that will be attached to the chart
      const rangeStart = dayjs(data[0][0].x);
      const rangeEnd = dayjs(data[0][data[0].length - 1].x);
      const days = Math.abs(rangeStart.diff(rangeEnd, 'day'));

      if (days <= 7) {

        setLabelFormat({ tickValues: 'every 1 days', format: '%b %d' });

      } else if (days > 7 && days <= 15) {

        setLabelFormat({ tickValues: 'every 2 days', format: '%b %d' });

      } else if (days > 15 && days <= 30) {

        setLabelFormat({ tickValues: 'every 7 days', format: '%b %d' });

      } else if (days > 30 && days <= 90) {

        setLabelFormat({ tickValues: 'every 15 days', format: '%b %d' });

      } else if (days > 90 && days <= 180) {

        setLabelFormat({ tickValues: 'every 1 month', format: '%b %Y' });

      } else if (days > 180 && days <= 365) {

        setLabelFormat({ tickValues: 'every 2 month', format: '%b %Y' });

      } else if (days > 365 && days <= 730) {

        setLabelFormat({ tickValues: 'every 4 month', format: '%b %Y' });

      } else if (days > 730) {

        setLabelFormat({ tickValues: 'every 6 month', format: '%b %Y' });

      }

    }

  }, [data]);

  const processedData = data[0].map((item, index) => ({
    x: dayjs(item.x).toDate(),
    y: item.y !== null ? item.y : data[1][index].y
  }));

  return (
      <ResponsiveLine
          data={[{ id: 'Running PnL', data: processedData }]}
          sliceTooltip={(point) => (
              <div
                  style={{
                    backgroundColor: '#263238D9',
                    color: '#e0e3eb',
                    fontWeight: 400,
                    borderRadius: '0.5rem',
                    padding: '0.5rem',
                    lineHeight: 'normal',
                    fontSize: '0.75rem'
                  }}
                    >
                  <div>${point.slice.points[0].data.y.toFixed(2)}</div>
                  <div>{dayjs(point.slice.points[0].data.x).format('MMMM D, YYYY')}</div>
              </div>
          )}
          curve='linear'
          isInteractive={!sparkLineMode}
          margin={sparkLineMode ? { top: 2, bottom: 2, left: 15, right: 15 } : { top: 20, right: 20, bottom: 60, left: 60 }}
          xScale={{
            type: 'time',
            format: '%Y-%m-%d',
            precision: 'day',
            useUTC: false
          }}
          yScale={{
            max: 'auto',
            min: 'auto',
            stacked: false,
            type: 'linear'
          }}
          yFormat='>-.2f'
          axisTop={null}
          axisRight={null}
          axisBottom={sparkLineMode ? null : {
            tickValues: labelFormat.tickValues,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: labelFormat.format
          }}
          axisLeft={sparkLineMode ? null : {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: '$'
          }}
          enableArea
          enableGridX={false}
          defs={[
            linearGradientDef('positiveGradient', [
              { offset: 0, color: '#29a373', opacity: 0.4 },
              { offset: 100, color: '#29a373', opacity: 0 }
            ]),
            linearGradientDef('negativeGradient', [
              { offset: 0, color: '#E57373', opacity: 0 },
              { offset: 100, color: '#E57373', opacity: 0.4 }
            ])
          ]}
          enableGridY={false}
          enablePoints={!sparkLineMode && data[0].length === 1}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          lineWidth={2}
          enableSlices='x'
          useMesh={true}
          layers={['axes', ZeroLineLayer, AreaLayer, LineLayer, 'crosshair', 'slices', 'points', 'legends']}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
    />
  );

};

RunningPnLLineChart.propTypes = {

  data: PropTypes.array,
  sparkLineMode: PropTypes.bool

};

export default RunningPnLLineChart;
