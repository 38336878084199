import React from 'react';
import { Card, Col, Row, Space, Statistic, Typography } from 'antd';
import PropTypes from 'prop-types';

import HorizontalBarChart from '../../../../components/horizontal-bar-chart';
import DailyPnLBarChart from '../../../../components/visualizations/daily-pnl-bar-chart';
import RunningPnLLineChart from '../../../../components/visualizations/running-pnl-chart';
import { dashboard } from '../../../../utilities/descriptions';
import { FORMAT } from '../../../../utilities/functions';
import DescriptionIcon from '../description-icon';

// import Sparklines from '../sparklines';
import './styles.scss';

const { Title, Text } = Typography;

const StatsCards = ({
  netPnL,
  averageNetPnLWin,
  averageNetPnLLoss,
  maxPnL,
  minPnL,
  totalWins,
  totalWinsPerDay,
  avgTradesPerDay,
  totalLosses,
  totalLossesPerDay,
  totalTrades,
  totalBreakeven,
  totalBreakevenPerDay,
  dailyData,
  PnLPerDayAccumulated
}) => {

  const netPnLColor = FORMAT.CURRENCY_TO_NUMBER(netPnL) > 0 ? { color: '#29a373' } : { color: '#E57373' };

  return (
      <Space direction='vertical' style={{ width: '100%' }} wrap>
          <Row gutter={[20, 20]} style={{ width: '100%' }}>
              <Col span={8}>
                  <Card>
                      <Row gutter={[24, 24]} style={{ width: '100%' }}>
                          <Col span={12} className='left-column'>
                              <Title level={5}>Net P&L <DescriptionIcon description={dashboard.netPnL} /></Title>
                              <Statistic
                                  value={netPnL}
                                  precision={2}
                                  valueStyle={{ fontWeight: 600, ...netPnLColor }} />
                          </Col>
                          <Col span={12}>
                              <div style={{ height: '4vh', marginTop: 30 }}>
                                  {PnLPerDayAccumulated && <RunningPnLLineChart data={PnLPerDayAccumulated} sparkLineMode />}
                              </div>
                              {/* {PnLPerDayAccumulatedFormatted && <Sparklines data={PnLPerDayAccumulatedFormatted} />} */}
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Row gutter={[24, 24]} style={{ width: '100%' }}>
                          <Col span={12} className='left-column'>
                              <Title level={5}>Average Winner <DescriptionIcon description={dashboard.averageWinner} /></Title>
                              <Statistic
                                  value={FORMAT.NUMBER_TO_CURRENCY(averageNetPnLWin)}
                                  precision={2}
                                  valueStyle={{ fontWeight: 600 }} />
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Row gutter={[24, 24]} style={{ width: '100%' }}>
                          <Col span={12} className='left-column'>
                              <Title level={5}>Average Loser <DescriptionIcon description={dashboard.averageLoser} /></Title>
                              <Statistic
                                  value={FORMAT.NUMBER_TO_CURRENCY(averageNetPnLLoss)}
                                  precision={2}
                                  valueStyle={{ fontWeight: 600 }} />
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Row gutter={[24, 24]} style={{ width: '100%' }}>
                          <Col span={12} className='left-column'>
                              <Title level={5}>Average Winner/Loser <DescriptionIcon description={dashboard.averageWinnerLoser} /></Title>
                              <Statistic
                                  value={averageNetPnLLoss === 0 ? averageNetPnLWin : averageNetPnLWin / (averageNetPnLLoss * (-1))}
                                  precision={2}
                                  valueStyle={{ fontWeight: 600 }} />
                          </Col>
                          <Col span={12} className='right-column'>
                              <HorizontalBarChart
                                  data={{ greenData: averageNetPnLWin, redData: averageNetPnLLoss * (-1) }}
                                  legend={{ left: FORMAT.NUMBER_TO_CURRENCY(averageNetPnLWin), right: FORMAT.NUMBER_TO_CURRENCY(averageNetPnLLoss) }} />
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Row gutter={[24, 24]} style={{ width: '100%' }}>
                          <Col span={12} className='left-column'>
                              <Title level={5}>Average Trades <Text type='secondary'>/ Day</Text> <DescriptionIcon description={dashboard.averageTradesPerDay} /></Title>
                          </Col>
                          <Col span={12} className='right-column'>

                              <Statistic
                                  title='Number of Trades'
                                  value={avgTradesPerDay}
                                />
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Row gutter={[24, 24]} style={{ width: '100%' }}>
                          <Col span={12} className='left-column'>
                              <Title level={5}>Win % <DescriptionIcon description={dashboard.tradeWin} /></Title>
                              <Statistic
                                  value={(totalWins / totalTrades) * 100}
                                  precision={2}
                                  valueStyle={{ fontWeight: 600 }}
                                  suffix='%' />
                          </Col>
                          <Col span={12} className='right-column'>
                              <HorizontalBarChart
                                  data={{ greenData: totalWins, grayData: totalBreakeven, redData: totalLosses }}
                                  legend={{ left: totalWins, right: totalLosses }} />
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Row gutter={[24, 24]} style={{ width: '100%' }}>
                          <Col span={12} className='left-column'>
                              <Title level={5}>Day Win (%) <DescriptionIcon description={dashboard.dayWin} /></Title>
                              <Statistic
                                  value={(totalWinsPerDay / dailyData.length) * 100}
                                  precision={2}
                                  valueStyle={{ fontWeight: 600 }}
                                  suffix='%' />
                          </Col>
                          <Col span={12} className='right-column'>
                              <HorizontalBarChart
                                  data={{ greenData: totalWinsPerDay, grayData: totalBreakevenPerDay, redData: totalLossesPerDay }}
                                  legend={{ left: totalWinsPerDay, right: totalLossesPerDay }} />
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Row gutter={[24, 24]} style={{ width: '100%' }}>
                          <Col span={12} className='left-column'>
                              <Title level={5}>Largest Win <DescriptionIcon description={dashboard.largestWin} /></Title>
                              <Statistic
                                  value={maxPnL}
                                  precision={2}
                                  valueStyle={{ fontWeight: 600 }} />
                          </Col>
                          <Col span={12} className='right-column'>
                              {/* TODO: Implement the right logic */}
                              {/* <span>Oct 28, 2023</span> */}
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card>
                      <Row gutter={[24, 24]} style={{ width: '100%' }}>
                          <Col span={12} className='left-column'>
                              <Title level={5}>Largest Loss <DescriptionIcon description={dashboard.largestLoss} /></Title>
                              <Statistic
                                  value={minPnL}
                                  precision={2}
                                  valueStyle={{ fontWeight: 600 }} />
                          </Col>
                          <Col span={12} className='right-column'>
                              {/* TODO: Implement the right logic */}
                              {/* <span>Oct 28, 2023</span> */}
                          </Col>
                      </Row>
                  </Card>
              </Col>
          </Row>
          <Row gutter={[20, 20]} style={{ width: '100%', paddingTop: '3.375rem' }}>
              <Col span={8}>
                  <Card title='Net P&L' extra={<DescriptionIcon description={dashboard.runningPnl} />}>
                      <div style={{ height: '25vh' }}>
                          {PnLPerDayAccumulated && <RunningPnLLineChart data={PnLPerDayAccumulated} />}
                      </div>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card title='Daily P&L' extra={<DescriptionIcon description={dashboard.dailyPnl} />}>
                      <div style={{ height: '25vh' }}>
                          {dailyData && <DailyPnLBarChart label='Daily P&L' data={dailyData} />}
                      </div>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card title='Daily Win Rate'>
                      <div style={{ height: '25vh' }}>
                          {dailyData && <DailyPnLBarChart label='Daily Win Rate' data={dailyData} />}
                      </div>
                  </Card>
              </Col>
          </Row>
      </Space>
  );

};

StatsCards.propTypes = {

  netPnL: PropTypes.string,
  avgPnL: PropTypes.string,
  averageNetPnLWin: PropTypes.number,
  averageNetPnLLoss: PropTypes.number,
  //   avgPnLPerDay: PropTypes.string,
  avgPnLPerMonth: PropTypes.string,
  avgPnLPerYear: PropTypes.string,
  avgTradesPerDay: PropTypes.string,
  maxPnL: PropTypes.string,
  minPnL: PropTypes.string,
  maxPnLPerDay: PropTypes.string,
  minPnLPerDay: PropTypes.string,
  maxPnLPerMonth: PropTypes.string,
  minPnLPerMonth: PropTypes.string,
  maxPnLPerYear: PropTypes.string,
  minPnLPerYear: PropTypes.string,
  dailyData: PropTypes.array,
  PnLPerDayAccumulated: PropTypes.array,
  PnLPerDayAccumulatedFormatted: PropTypes.array,
  totalTrades: PropTypes.number,
  totalWins: PropTypes.number,
  totalLosses: PropTypes.number,
  totalWinsPerDay: PropTypes.number,
  totalLossesPerDay: PropTypes.number,
  totalBreakevenPerDay: PropTypes.number,
  totalBreakeven: PropTypes.number
};

export default StatsCards;
