import React, { useEffect } from 'react';
import { Button, ConfigProvider, Layout, message, Space, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { useNavigate } from 'react-router';
import { Outlet, useLocation } from 'react-router-dom';

import { useAccountStore, useManagementStore, useUserState } from '../../store';
import axios from '../../utilities/axios';
import { FORMAT, LOGS } from '../../utilities/functions';

import Header from './components/header';
import HotjarComponent from './components/hotjar';
import Sidebar from './components/sidebar';
import SubHeader from './components/subheader';

const { Title, Text } = Typography;
// import AddAccount from "./components/add-account";

message.config({
  duration: 10
});

function LayoutMain() {

  const [, contextHolder] = message.useMessage();

  // LOADING ALL ACCOUNTS AND CURRENT SELECTED ACCOUNT
  const { allAccounts, setAllAccounts } = useAccountStore((state) => state);
  const { getUser } = useUserState();
  const { viewAs } = useManagementStore((state) => state);
  const { pathname } = useLocation(); // Import useLocation from react-router-dom
  const isSettingsRoute = pathname.startsWith('/settings');
  const isCompareRoute = pathname === '/compare';
  const isAddTradeRoute = pathname === '/add-trade';
  const isTradeDetailRoute = pathname.startsWith('/trades/');

  const user = getUser();
  const navigate = useNavigate();

  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);

  const fetchAccounts = () => axios.get('crud/account', {
    headers: { token: isViewAsActive ? viewAs.newUser.token : user?.token }
  }).then(({ data }) => data.map((a) => FORMAT.ACCOUNTS_BEAUTIFY(a)));

  useEffect(() => {

    if (user.token !== undefined) {

      fetchAccounts().then(async (accounts) => {

        const results = await LOGS.processLogs(accounts, setAllAccounts);
        return results;

      });

    }

  }, [user.token, viewAs]);

  // Check if allAccounts is empty and the route is not /settings
  const shouldDisplayCreateAccountMessage = allAccounts.length === 0 && !isSettingsRoute;

  const renderContent = () => {

    if (shouldDisplayCreateAccountMessage) {

      if (!isViewAsActive) {

        return (
            <Space direction='vertical' className='no-data' wrap>
                <Title>Create a trading account to get started.</Title>
                <Button type='primary' onClick={() => navigate('settings')}>New Trading Account</Button>
                <Text>After creating a trading account, you will be able to import your trade logs.</Text>
                <Text>You can create multiple accounts and journal your trades independently. This can be useful if you use multiple brokerages.</Text>
            </Space>
        );

      }

      return <Space direction='vertical' className='no-data' wrap><Title>User doesn’t have any trading account yet.</Title></Space>;

    }
    return <Outlet />;

  };

  return (
      <ConfigProvider
          theme={{
            token: {
              borderRadius: 8,
              fontFamily: "'Inter', sans-serif"
            },
            components: {
              Menu: {
                itemSelectedBg: '#2962FE',
                itemSelectedColor: '#E6E9EB',
                itemColor: '#78909C'
              },
              Pagination: {
                itemActiveBg: '#455A64',
                colorPrimary: '#E0E3EB',
                colorPrimaryHover: '#fafafa'
              }
            }
          }}
      >
          <HotjarComponent ></HotjarComponent>
          <Layout>
              {contextHolder}
              <Sider className='custom-sider'>
                  <Sidebar />
              </Sider>
              <Layout className='app'>
                  <Header/>
                  { !isSettingsRoute && !isCompareRoute && !isTradeDetailRoute && !isAddTradeRoute && <SubHeader/> }
                  <Content className='content' style={{
                    paddingLeft: '1.25rem'
                  }}>
                      {renderContent()}
                  </Content>
              </Layout>
          </Layout>
      </ConfigProvider>

  );

}

export default LayoutMain;
