import React from 'react';
import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router';

import Logo from '../logo';

import './styles.scss';

function getItem(label, key, icon, children, type) {

  return {
    key,
    icon,
    children,
    label,
    type
  };

}
const items = [
  getItem('Dashboard', '/', <span className='tt-overview' style={{ fontSize: 18 }} />),
  getItem('Calendar', '/calendar', <span className='tt-calendar' style={{ fontSize: 18 }} />),
  getItem('Statistics', '/summary', <span className='tt-bar_chart_4_bars' style={{ fontSize: 18 }} />),
  getItem('Trades', '/trades', <span className='tt-feed' style={{ fontSize: 18 }} />),
  getItem('Compare', '/compare', <span className='tt-compare' style={{ fontSize: 18 }} />),
  getItem('Daily Notes', '/daily-trade-notes', <span className='tt-Daily-Notes' style={{ fontSize: 18 }} />)
  // getItem('Daily Trades', '/daily', <span className='tt-calendar-today' style={{ fontSize: 18 }} />)
];
const Sidebar = () => {

  const navigate = useNavigate();

  const location = useLocation();

  const onClick = (e) => {

    navigate(e.key);

  };
  return (
      <>
          <Logo />
          <div className='menu-container'>
              <Menu
                  onClick={onClick}
                  style={{
                    paddingTop: '2rem',
                    height: '100%',
                    backgroundColor: '#E6E9EB',
                    color: '#78909C',
                    fontSize: '1rem',
                    fontWeight: '600',
                    lineHeight: '1.5rem',
                    border: 'none'
                  }}
                  selectedKeys={[location.pathname]}
                  mode='inline'
                  items={items} />
          </div>

      </>

  );

};
export default Sidebar;
