/* eslint-disable */

import { useEffect } from 'react';

import './styles.scss';

const HotjarComponent = () => {
  useEffect(() => {
  
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3206159, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

    findHotJarAndAddCloseButton();
  }, []);

  function addCloseButton() {

    const hasHotjarBtn =
    document.querySelector('._hj-eugsI__styles__buttonPositionTarget > button')?.ariaLabel ===
    'Close';

    const closeBtn = document.querySelector('#close-button-hotjar');
    if (!closeBtn && !hasHotjarBtn) {
        const closeButton = document.createElement('button');
        closeButton.innerHTML = '&#10005;';
        closeButton.className = 'close-button-hotjar';
        closeButton.id = 'close-button-hotjar';
        closeButton.title =
            "close the feedback, you'll be able to see the feedback again with refreshing the page!";
        closeButton.addEventListener('click', () => {
            const hotjarContainer = document.querySelector(
                '._hj-widget-container'
            );
            hotjarContainer.style.display = 'none';
        });

        // for opening and closing the hotjar
        document
            .querySelector('._hj-eugsI__styles__buttonPositionTarget')
            ?.addEventListener('click', () => {
                setTimeout(() => {
                    addCloseButton();

                    // hopefully after 500ms DOM has been updated!
                }, 500);
            });

        document
            .querySelector('._hj-eugsI__styles__buttonPositionTarget')
            ?.appendChild(closeButton);
    }
  }

  function findHotJarAndAddCloseButton(timeOut = 5000) {
      const node = document.querySelector('._hj-eugsI__styles__buttonPositionTarget');
      if (node) {
          addCloseButton();
      } else {
          setTimeout(
              () => findHotJarAndAddCloseButton(Math.min(60000, Math.floor(timeOut * 1.5))),
              timeOut
          );
      }
  }

  return null;
};

export default HotjarComponent;
