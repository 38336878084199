import { React, useState } from 'react';
import { Button, Card, Checkbox, Col, Dropdown, Input, Row, Space, Switch, Table } from 'antd';
import qs from 'qs';

import { DownOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';

import { useAccountStore, useUserState } from '../../store';
import axios from '../../utilities/axios';
import { FORMAT } from '../../utilities/functions';

import './styles.scss';

const fetchTags = (user) => {

  const results = axios
    .get('crud/tag', { headers: { token: user?.token } })
    .then(({ data }) => data)
    .catch((error) => {

      if (error.response.status === 404) {

        return [];

      }
      return error;

    });

  return results;

};

const columns = [
  {
    title: '',
    dataIndex: 'key',
    rowScope: 'row',
    render: (item) => (
        <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>{item}</p>
    )
  },
  {
    title: 'Group A',
    dataIndex: 'groupA',
    key: 'groupA',
    render: (item, r) => (
        <p
            title='Winner'
            style={r.winner.includes('groupA') && r.winner.length < 3 ? { fontWeight: 'bold', fontSize: '1rem' } : { fontSize: '1rem' }}>
            {item} {r.winner.includes('groupA') && r.winner.length < 3 ? '✅' : ''}
        </p>
    )
  },
  {
    title: 'Group B',
    dataIndex: 'groupB',
    key: 'groupB',
    render: (item, r) => (
        <p
            title='Winner'
            style={r.winner.includes('groupB') && r.winner.length < 3 ? { fontWeight: 'bold', fontSize: '1rem' } : { fontSize: '1rem' }}>
            {item} {r.winner.includes('groupB') && r.winner.length < 3 ? '✅' : ''}
        </p>
    )
  },
  {
    title: 'Group C',
    dataIndex: 'groupC',
    key: 'groupC',
    render: (item, r) => (
        <p
            title='Winner'
            style={r.winner.includes('groupC') && r.winner.length < 3 ? { fontWeight: 'bold', fontSize: '1rem' } : { fontSize: '1rem' }}>
            {item} {r.winner.includes('groupC') && r.winner.length < 3 ? '✅' : ''}
        </p>
    )
  }
];

export default function CompareCard() {

  const [selectedTagsA, setSelectedTagsA] = useState([]);
  const [selectedTagsB, setSelectedTagsB] = useState([]);
  const [selectedTagsC, setSelectedTagsC] = useState([]);
  const [selectedStockA, setSelectedStockA] = useState([]);
  const [selectedStockB, setSelectedStockB] = useState([]);
  const [selectedStockC, setSelectedStockC] = useState([]);
  const [showAllTagsA, setShowAllTagsA] = useState();
  const [showAllTagsB, setShowAllTagsB] = useState();
  const [showAllTagsC, setShowAllTagsC] = useState();

  const [compareResult, setCompareResult] = useState();

  const { allAccounts } = useAccountStore((state) => state);

  const allAccountsId = allAccounts.map((o) => o.key);

  const { getUser } = useUserState();
  const user = getUser();

  const { data: tags = [] } = useQuery(['tags'], () => fetchTags(user));

  const onChangeTagsA = (value) => setSelectedTagsA(value);
  const onChangeTagsB = (value) => setSelectedTagsB(value);
  const onChangeTagsC = (value) => setSelectedTagsC(value);
  const onChangeStockA = (value) => setSelectedStockA(value);
  const onChangeStockB = (value) => setSelectedStockB(value);
  const onChangeStockC = (value) => setSelectedStockC(value);

  const fetchCompare = (accounts, tagsA, tagsB, tagsC, showTagsA, showTagsB, showTagsC, stockA, stockB, stockC) => axios.get('stats/compare', {
    params: { accounts, tagsA, tagsB, tagsC, showTagsA, showTagsB, showTagsC, stockA, stockB, stockC },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
  }).then((response) => {

    let responseData = null;

    if (response.status === 200) {

      responseData = FORMAT.COMPARE_TABLE_BEAUTIFY(response.data);

    }

    return responseData;

  }).catch((error) => {

    console.error('Error:', error);

  });

  const handleCompare = async () => {

    const compareData =
      await fetchCompare(allAccountsId, selectedTagsA, selectedTagsB, selectedTagsC, showAllTagsA, showAllTagsB, showAllTagsC, selectedStockA, selectedStockB, selectedStockC);
    setCompareResult(compareData);

  };

  const tagMenu = (showAllTags, setShowAllTags, selectedTags, setSelectedTags, onChangeTags) => (
      <div className='tag-menu'>
          <div className='tag-menu__switch-container'>
              <span className='tag-menu__switch-label'>Only show results that contain all selected tags</span>
              <Switch
                  checked={showAllTags}
                  onChange={(checked) => setShowAllTags(checked)}
                  size='small'
            />
          </div>
          <div className='tag-menu__content'>
              {Object.entries(
                tags.reduce((acc, tag) => {

                  const category = tag.categoryLabel || 'Uncategorized';
                  if (!acc[category]) acc[category] = [];
                  acc[category].push(tag);
                  return acc;

                }, {})
              ).map(([category, categoryTags]) => (
                  <div key={category} className='tag-menu__category'>
                      <h4 className='tag-menu__category-title'>{category}</h4>
                      {categoryTags.map((tag) => (
                          <div key={tag._id} className='tag-menu__checkbox-container'>
                              <Checkbox
                                  checked={selectedTags.includes(tag._id)}
                                  onChange={(e) => {

                                    setSelectedTags((prev) => {

                                      const newTags = e.target.checked
                                        ? [...prev, tag._id]
                                        : prev.filter((t) => t !== tag._id);
                                      return newTags;

                                    });

                                  }}
                            >
                                  {tag.label}
                              </Checkbox>
                          </div>
                      ))}
                  </div>
              ))}
          </div>
          <div className='tag-menu__button-container'>
              <Button
                  style={{ color: '#2962FF' }}
                  onClick={() => {

                    setSelectedTags([]);
                    setShowAllTags(false);

                  }}>Reset</Button>
              <Button
                  type='primary'
                  onClick={() => {

                    onChangeTags(selectedTags);
                    setShowAllTags(showAllTags);
                    // Close the dropdown here if needed

                  }}
            >
                  Apply
              </Button>
          </div>
      </div>
  );

  return (
      <Space direction='vertical' style={{ width: '100%', paddingTop: '3.375rem' }} wrap>
          <Row gutter={[20, 20]} style={{ width: '100%', paddingBottom: '1.375rem' }}>
              <Col span={8}>
                  <Card title='Group A' className='grey-card'>
                      <Row gutter={[10, 10]} style={{ width: '100%' }}>
                          <Col span={8}>
                              <span>Symbol: </span>
                              <Input placeholder='Symbol' onChange={(e) => onChangeStockA(e.currentTarget.value)} />
                          </Col>
                          <Col span={16}>
                              <span>Tags: </span>
                              <Dropdown
                                  overlay={() => tagMenu(showAllTagsA, setShowAllTagsA, selectedTagsA, setSelectedTagsA, onChangeTagsA)}
                                  trigger={['click']}
                                  placement='bottomLeft'
                                  >
                                  <Button className='tag-button'>
                                      <div className='tag-button__content'>
                                          {selectedTagsA.length > 0 && (
                                          <span className='tag-button__count'>
                                              {selectedTagsA.length}
                                          </span>
                                          )}
                                      </div>
                                      <DownOutlined className='tag-button__arrow' />
                                  </Button>
                              </Dropdown>
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card title='Group B' className='grey-card'>
                      <Row gutter={[10, 10]} style={{ width: '100%' }}>
                          <Col span={8}>
                              <span>Symbol: </span>
                              <Input placeholder='Symbol' onChange={(e) => onChangeStockB(e.currentTarget.value)} />
                          </Col>
                          <Col span={16}>
                              <span>Tags: </span>
                              <Dropdown
                                  overlay={() => tagMenu(showAllTagsB, setShowAllTagsB, selectedTagsB, setSelectedTagsB, onChangeTagsB)}
                                  trigger={['click']}
                                  placement='bottomLeft'
                                  >
                                  <Button className='tag-button'>
                                      <div className='tag-button__content'>
                                          {selectedTagsB.length > 0 && (
                                          <span className='tag-button__count'>
                                              {selectedTagsB.length}
                                          </span>
                                          )}
                                      </div>
                                      <DownOutlined className='tag-button__arrow' />
                                  </Button>
                              </Dropdown>
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={8}>
                  <Card title='Group C' className='grey-card'>
                      <Row gutter={[10, 10]} style={{ width: '100%' }}>
                          <Col span={8}>
                              <span>Symbol: </span>
                              <Input placeholder='Symbol' onChange={(e) => onChangeStockC(e.currentTarget.value)} />
                          </Col>
                          <Col span={16}>
                              <span>Tags: </span>
                              <Dropdown
                                  overlay={() => tagMenu(showAllTagsC, setShowAllTagsC, selectedTagsC, setSelectedTagsC, onChangeTagsC)}
                                  trigger={['click']}
                                  placement='bottomLeft'
                                  >
                                  <Button className='tag-button'>
                                      <div className='tag-button__content'>
                                          {selectedTagsC.length > 0 && (
                                          <span className='tag-button__count'>
                                              {selectedTagsC.length}
                                          </span>
                                          )}
                                      </div>
                                      <DownOutlined className='tag-button__arrow' />
                                  </Button>
                              </Dropdown>
                          </Col>
                      </Row>
                  </Card>
              </Col>
          </Row>
          <Button size='large' onClick={handleCompare} type='primary' >Compare</Button>
          <Row gutter={[0, 0]} style={{ width: '100%', paddingTop: '1.375rem' }}>
              <Col span={24}>
                  {compareResult && <Table
                    //   rowKey={(r) => r.date + r.remainingShares} // trying unique key
                      columns={columns}
                      dataSource={compareResult}
                      pagination={false}
                    /> }
              </Col>
          </Row>
      </Space>
  );

}
