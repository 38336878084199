import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

import LineChartVirtualize from './lineChart';

const DailyPnLLineChart = ({ data, date }) => {

  const [line, setLine] = useState(null);

  const chartOptions = {
    scales: {
      x: {
        type: 'category',
        title: {
          display: false,
          text: 'Time'
        },
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: true
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context) => {

            const value = context.parsed.y;
            return [`$${value.toFixed(2)}`, context.label];

          },
          labelColor: () => '#465156',
          title: () => null
        }
      }
    }
  };

  useEffect(() => {

    const selectedDate = {
      year: parseInt(date.slice(0, 4), 10),
      month: parseInt(date.slice(5, 7), 10) - 1,
      day: parseInt(date.slice(8, 10), 10)
    };

    const accumulatedData = data.flatMap((trade) => trade.executions
      .filter((execution) => {

        const executionDate = new Date(execution.date);
        return executionDate.getFullYear() === selectedDate.year &&
               executionDate.getMonth() === selectedDate.month &&
               executionDate.getDate() === selectedDate.day;

      })
      .map((execution) => ({
        pnl: execution.netRealizedGain,
        time: new Date(execution.date)
      }))).sort((a, b) => a.time - b.time);

    let accumulatedPnL = 0;
    const chartData = accumulatedData.map(({ pnl, time }) => {

      accumulatedPnL += pnl;
      return {
        netPnL: accumulatedPnL,
        closeTime: time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      };

    });

    const dataToChart = LineChartVirtualize.LineChart(
      chartData.map((x) => x.netPnL),
      chartData.map((x) => x.closeTime)
    );

    setLine(dataToChart);

  }, [data, date]);

  return (
      <>
          {line && (
          <Line
              data={line.data}
              options={{
                ...line.options,
                ...chartOptions
              }}
        />
          )}
      </>
  );

};

DailyPnLLineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      netPnL: PropTypes.number.isRequired,
      closeTime: PropTypes.string.isRequired
    })
  ).isRequired,
  date: PropTypes.string.isRequired
};

export default DailyPnLLineChart;
