/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Menu, message, Modal, Popconfirm, Row, Space, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useDebounceCallback } from 'usehooks-ts';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useManagementStore, useUserState } from '../../../store';
import axios from '../../../utilities/axios';
import NotesEditor from '..';

import './styles.scss';

const fetchTemplates = (token) => axios.get('crud/template', {
  headers: {
    token
  }
}).then(({ data }) => data);

function InitialStatePlugin({ initialState }) {

  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {

    if (initialState) {

      const parsedState = editor.parseEditorState(initialState);
      if (!parsedState.isEmpty()) {

        editor.setEditorState(parsedState);

      }

    }

  }, [editor, initialState]);

  return null;

}

InitialStatePlugin.propTypes = {
  initialState: PropTypes.string
};

const { Title } = Typography;

/**
 * This component is used to manage and use templates for notes.
 *
 * If the user is not in view-as mode (not an admin inside other users' accounts), the component will render a list of all
 * templates the user has created. The user can select any of these templates to
 * use for their notes.
 *
 * The component also allows the user to create a new template, edit an existing
 * template, and delete a template.
 *
 * The component will also display a modal if the user clicks on the manage
 * templates button. This modal will allow the user to manage their templates.
 *
 * @param {function} SubmitButton - The submit button for the notes editor.
 * @param {function} CancelButton - The cancel button for the notes editor.
 * @param {function} setNotesContent - A function to set the content of the notes editor (called on change).
 * @param {string} existingContent - The current content of the notes editor (the content to append).
 * @param {function} setExistingContent - A function to set the existing content of the notes editor.
 * @returns {ReactElement} - The notes editor with the template management component.
 */
export default function NotesTemplateEditor({ SubmitButton,
  CancelButton,
  setNotesContent,
  existingContent,
  setExistingContent,
  titleFunction = null }) {

  const [templateContent, setTemplateContent] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [contentToAppend, setContentToAppend] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [showPopConfirm, setShowPopConfirm] = useState(false);

  const debounceSetSelectedTemplate = useDebounceCallback(setSelectedTemplate, 350);

  const { getUser } = useUserState();
  const user = getUser();
  const { viewAs } = useManagementStore((state) => state);

  const { confirm } = Modal;

  const isViewAsActive = JSON.stringify(viewAs?.newUser?.token || null) !== JSON.stringify(viewAs?.mainUser?.token || null);
  const { data: templates = [], refetch } = useQuery(['templates'], () => fetchTemplates(user?.token), { enabled: user.token !== undefined });

  const postTemplate = ({ token, templateData }) => {

    axios.post(
      'crud/template',
      {
        template: templateData.template,
        name: templateData.name
      },
      {
        headers: {
          token
        }
      }
    ).then(({ data }) => data)
      .then((data) => {

        setSelectedTemplate(data);
        refetch();

      })
      .then(() => {

        setIsEditMode(false);
        setTemplateName(templateName);
        message.success('Template is created');

      })
      .catch(() => message.error('Failed to create template'));

  };

  const updateTemplate = ({ token, id: templateId, templateData }) => axios.put(
    `crud/template/${templateId}`,
    {
      template: templateData.template,
      name: templateData.name
    },
    {
      headers: {
        token
      }
    }
  ).then(({ data }) => {

    refetch();
    return data;

  })
    .catch(() => message.error('Failed to update the template.'));

  const deleteTemplate = ({ token, id: templateId }) => axios.delete(`crud/template/${templateId}`, {
    headers: {
      token
    }
  });

  const mutateCreateTemplate = useMutation(postTemplate);

  const mutateUpdateTemplate = useMutation(updateTemplate);

  const mutateDeleteTemplate = useMutation(deleteTemplate, {
    onSuccess: async () => {

      await refetch();
      message.success('Template deleted successfully');

    },
    onError: () => {

      message.error('Failed to delete the template');

    }
  });

  const handleTemplateModal = () => {

    setSelectedTemplate(null);

    if (!isTemplateModalOpen) {

      setIsTemplateModalOpen(true);
      return;

    }

    if (isEditMode) {

      setIsEditMode(false);
      if (!selectedTemplate) {

        setSelectedTemplate(templates[0]);
        setTemplateName(templates[0].name);

      } else {

        debounceSetSelectedTemplate(selectedTemplate);
        setTemplateName(selectedTemplate.name);

      }
      setShowPopConfirm(false);
      return;

    }

    setIsTemplateModalOpen(false);
    setTemplateName('');
    setExistingContent('');
    setTemplateContent('');

  };

  const onTemplateSubmit = async () => {

    if (selectedTemplate && !isEditMode) {

      setContentToAppend(JSON.parse(selectedTemplate.template));
      setIsTemplateModalOpen(false);
      setTemplateContent('');
      setTemplateName('');

      return;

    }

    if (!templateName.trim()) {

      message.error('Template name is required');
      return;

    }
    if (!templateContent) {

      message.error('Template content is required');
      return;

    }

    if (selectedTemplate && isEditMode) {

      await mutateUpdateTemplate.mutateAsync({
        token: user?.token,
        id: selectedTemplate?._id,
        templateData: {
          name: templateName,
          template: templateContent
        }
      });

      setIsEditMode(false);

      message.success('All changes to the template have been saved.');

      return;

    }

    if (!selectedTemplate) {

      const templateData = {
        name: templateName,
        template: templateContent
      };

      await mutateCreateTemplate.mutateAsync({
        token: user?.token,
        templateData
      });

    }

  };

  const handleNewTemplate = () => {

    setIsEditMode(true);
    setSelectedTemplate(null);
    setTemplateName('');

  };

  const handleDeleteTemplate = async () => {

    await mutateDeleteTemplate.mutateAsync({ token: user?.token, id: selectedTemplate._id });

    const filteredTemplates = templates.filter((template) => template._id !== selectedTemplate._id);

    if (filteredTemplates.length > 0) {

      setSelectedTemplate(filteredTemplates[0]);
      setTemplateName(filteredTemplates[0].name);

    } else {

      handleNewTemplate();

    }

  };

  const handleTemplateSelection = (item) => {

    setSelectedTemplate(templates[item.key]);
    setTemplateName(templates[item.key].name);
    setIsEditMode(false);

  };

  useEffect(() => {

    if (isTemplateModalOpen && templates.length > 0) {

      handleTemplateSelection({ key: 0 });

    }

  }, [isTemplateModalOpen]);

  const okText = () => {

    if (selectedTemplate && isEditMode) return 'Save Template';
    if (!selectedTemplate) return 'Create a Template';
    return 'Use Template';

  };

  const ConfirmCancelModal = (isBack = false) => {

    confirm({
      title: 'Discard Changes?',
      content: 'Are you sure you want to discard your changes? Any unsaved edits will be lost.',
      okText: 'Discard',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: <ExclamationCircleOutlined style={{ color: '#F23645' }} />,
      okButtonProps: {
        style: { backgroundColor: '#F23645', borderColor: '#F23645', color: 'white' }
      },
      cancelButtonProps: {
        style: { backgroundColor: '#F5F7FA', borderColor: '#F5F7FA', color: '#78909C' }
      },
      onOk() {

        if (titleFunction) {

          if (isBack === false) {

            titleFunction();

          }

          setIsTemplateModalOpen(false);
          setTemplateName('');
          setExistingContent('');
          setTemplateContent('');

        }

        handleTemplateModal();

      }
    });

  };

  return (
      <>
          <div className='cardFooter' id='editoSection'>
              {!isViewAsActive &&
              <div className='manage-templates'>
                  <Title level={4}>Your Templates</Title>
                  <Title level={5}>Select any of these templates for your notes.</Title>
                  <div className='templates-tags'>
                      {templates.length > 0 &&
                            templates.map((x) => (
                                <Tag color='#CBD7FF' onClick={() => setContentToAppend(JSON.parse(x.template))} className='templates-tag' key={x._id}>{x.name}</Tag>
                            ))}
                      <Tag color='#E0E3EB' onClick={handleTemplateModal}><span className='tt-edit' /> Manage Your Templates</Tag>
                  </div>
                  {/* TODO: Make this a separate component */}
                  <Modal
                      open={isTemplateModalOpen}
                      destroyOnClose
                      onCancel={() => (isEditMode ? ConfirmCancelModal() : setIsTemplateModalOpen(false))}
                      footer={[
                          <>

                              <Button onClick={() => {

                                if (isEditMode) {

                                  ConfirmCancelModal();

                                } else {

                                  handleTemplateModal();

                                }

                              }}>Cancel</Button>
                              <Button type='primary' onClick={onTemplateSubmit} key='submit'>
                                  {okText()}
                              </Button>
                          </>
                      ]}
                      className='templates-modal'
                      title={titleFunction ?
                          <Button type='text'
                              onClick={() => {

                                if (isEditMode) {

                                  ConfirmCancelModal(true);

                                } else {

                                  handleTemplateModal();

                                }

                              }}
                          ><i className='tt-back' />Back </Button>
                        : 'Templates Management'}
                      okText={okText()}
                      okButtonProps={{
                        loading: (mutateCreateTemplate.isLoading || mutateUpdateTemplate.isLoading),
                        disabled: (!templateName || !templateContent)
                      }}
                        >
                      <Space align='start'>
                          <Col span={8} style={{ width: '14rem' }}>
                              <Button className='new-template' type='primary' onClick={handleNewTemplate}>
                                  <i className='tt-plus' /><span>&nbsp;New Template</span>
                              </Button>
                              <div className='templates-menu-container'>
                                  <Menu
                                      title='My Templates'
                                      defaultSelectedKeys={['0']}
                                      style={{ width: '14rem' }}
                                      theme='light'
                                      onClick={handleTemplateSelection}
                                      defaultOpenKeys={['sub1']}
                                      className='templates-menu'
                                      selectedKeys={templates.filter((x) => x._id === selectedTemplate?._id).length > 0 ?
                                        [`${templates.findIndex((x) => x._id === selectedTemplate?._id)}`] : []}
                                      mode='inline'
                                      items={[
                                        { key: 'sub1',
                                          label: 'My Templates',
                                          children: templates.map((x, index) => ({ label: x.name, key: index })) }
                                      ]}
                                  />
                              </div>
                          </Col>
                          <Col className='template-editor' span={24}>
                              <div className='template-editor-header'>
                                  {(!isEditMode && selectedTemplate) ?
                                      <Title style={{ marginTop: '0.25rem', marginBottom: 0, fontSize: '0.875rem' }} level={5}>{templateName}</Title> :
                                      <Input
                                          maxLength={40}
                                          disabled={!isEditMode && selectedTemplate}
                                          value={templateName}
                                          onChange={(e) => setTemplateName(e.target.value)} />}
                                  {(!isEditMode && selectedTemplate) &&
                                  <div className='template-editor-buttons'>
                                      <Button onClick={() => setIsEditMode(true)}><i className='tt-edit'/>&nbsp;Edit</Button>
                                      <Popconfirm
                                          title='Delete Template?'
                                          description=
                                              {<>
                                                  <div style={{ fontSize: '0.875rem' }}>Are you sure you want to delete this template?</div>
                                                  <div style={{ fontSize: '0.75rem', color: '#424242' }}>
                                                      This action will not affect existing notes that have used this template.
                                                  </div>
                                              </>}
                                          onConfirm={() => handleDeleteTemplate()}
                                          okButtonProps={{ loading: mutateDeleteTemplate.isLoading }}
                                          okText='Delete'
                                          placement='top'
                                          >
                                          <Button><i className='tt-trash' />&nbsp;Delete Template</Button>
                                      </Popconfirm>
                                  </div>}
                              </div>
                              <NotesEditor
                                  placeholder='Start writing something'
                                  onChange={setTemplateContent}
                                  content={selectedTemplate?.template}
                                  isReadOnly={(!isEditMode && !!selectedTemplate)}
                                  isTemplateModal={true}
                                  />
                          </Col>
                          <Popconfirm
                              key='cancel'
                              open={showPopConfirm}
                              title='Discard Changes?'
                              description='Are you sure you want to discard your changes? Any unsaved edits will be lost.'
                              onConfirm={handleTemplateModal}
                              onCancel={() => setShowPopConfirm(false)}
                              okText='Yes'
                              cancelText='No'
                              />
                      </Space>
                  </Modal>
              </div>
                  }
              <Row style={{ width: '100%', gap: 10, marginBottom: '20px' }} justify='center'>
                  <Col span={24}>
                      <NotesEditor
                          contentToAppend={contentToAppend}
                          SubmitButton={SubmitButton}
                          CancelButton={CancelButton}
                          placeholder='Start writing something or start from a template'
                          onChange={setNotesContent}
                          content={existingContent} />
                  </Col>
              </Row>
          </div>
      </>
  );

}
