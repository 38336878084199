/* eslint-disable import/no-extraneous-dependencies */
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import qs from 'qs';

import { useSettingStore } from '../store';

import axios from './axios';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const { brokers, profitCalculationMethods } = require('../resources/static/select-input-data');

const fetchLogs = (accountId) => axios.get(`crud/logs/${accountId}`).then(({ data }) => data);

/* FORMAT STRINGS, DATES, ETC */
export const FORMAT = {
  CURRENCY_MEASURE() {

    return useSettingStore.getState().filter.basic_measure;

  },
  TRADE_BEAUTIFY(o, timezone) {

    let realizedRMultiple;

    if (o.status === 'open') {

      realizedRMultiple = '-';

    } else {

      realizedRMultiple = o.realized_R_multiple ? `${o.realized_R_multiple.toFixed(2)}R` : '';

    }

    const tradeObj = {
      ...o,
      openTime: this.DATE_AND_TIME_WITH_FORMAT(o.openTime, timezone),
      closeTime: o.closeTime !== null ? this.DATE_AND_TIME_WITH_FORMAT(o.closeTime, timezone) : '',
      openPrice: this.NUMBER_TO_CURRENCY(o.openPrice),
      closePrice: o.status === 'open' ? '-' : this.NUMBER_TO_CURRENCY(o.closePrice),
      executions: o.executions.map((e, index) => this.EXECUTION_BEAUTIFY(e, index, o.executions.length, timezone)),
      netPnL: this.NUMBER_TO_CURRENCY(o.netPnL),
      totalVolume: o.status === 'open' ? '-' : o.totalVolume,
      totalCommission: this.NUMBER_TO_CURRENCY(o.totalCommission),
      optionContract: o.assetType === 'Option' ? this.DECOMPOSE_OPTION_STRING(o.symbol) : '',
      initialTarget: o.status === 'open' ? '-' : this.NUMBER_TO_CURRENCY(o.initial_target),
      tradeRisk: o.status === 'open' ? '-' : this.NUMBER_TO_CURRENCY(o.trade_risk),
      plannedRMultiple: o.planned_R_multiple ? `${o.planned_R_multiple.toFixed(2)}R` : '',
      realizedRMultiple
    };

    if (this.CURRENCY_MEASURE() === 'R-multiple') {

      return {
        ...tradeObj,
        netPnL: this.NUMBER_TO_R_MULTIPLE(o.realized_R_multiple)
      };

    }

    return tradeObj;

  },
  ACCOUNTS_BEAUTIFY(o) {

    return {
      // ...o,
      key: o._id,
      label: o.name,
      value: o._id,
      broker: o.broker,
      brokerLabel: this.BROKER_ID_TO_LABEL(o.broker),
      brokerTimezone: o.brokerTimezone,
      profitCalculationMethod: this.PROFIT_CALCULATION_METHOD_ID_TO_LABEL(o.profitCalculationMethod),
      numberOfTrades: o.numberOfTrades,
      notes: o.notes
    };

  },
  EXECUTION_BEAUTIFY(o, index, length, timezone) {

    return {
      ...o,
      date: this.DATE_AND_TIME_WITH_FORMAT(o.date, timezone),
      price: this.NUMBER_TO_CURRENCY(o.price),
      commission: this.NUMBER_TO_CURRENCY(o.commission),
      realizedGain: this.NUMBER_TO_CURRENCY(o.realizedGain),
      netRealizedGain: this.NUMBER_TO_CURRENCY(o.netRealizedGain),
      mark: this.EXECUTION_MARK_MAP(o, index, length, timezone)
    };

  },
  EXECUTION_MARK_MAP(o, index, length, timezone) {

    if (index === 0) {

      return {
        id: index,
        time: dayjs(o.date).tz(timezone).valueOf() / 1000,
        side: o.side.toLowerCase(),
        color: o.side === 'SELL' ? '#E57373' : '#29a373',
        shape: o.side === 'SELL' ? 'arrow_down' : 'arrow_up',
        price: o.price,
        text: `OPEN - ${o.side} @ ${o.price}`,
        textColor: '#000',
        overrides: {
          fontsize: 14,
          color: '#000'
        }
      };

    } if (index === length - 1 && o.remainingShares === 0) {

      return {
        id: index,
        time: dayjs(o.date).valueOf() / 1000,
        side: o.side.toLowerCase(),
        color: o.side === 'SELL' ? '#E57373' : '#29a373',
        shape: o.side === 'SELL' ? 'arrow_down' : 'arrow_up',
        price: o.price,
        text: `CLOSE - ${o.side} @ ${o.price}`,
        textColor: '#000',
        overrides: {
          fontsize: 14,
          color: '#000'
        }
      };

    }

    return {
      id: index,
      time: dayjs(o.date).valueOf() / 1000,
      side: o.side.toLowerCase(),
      color: o.side === 'SELL' ? '#E57373' : '#29a373',
      shape: o.side === 'SELL' ? 'icon' : 'icon',
      price: o.price,
      text: `${o.side} @ ${o.price}`,
      textColor: '#000',
      overrides: {
        icon: o.side === 'SELL' ? 0xf0d7 : 0xf0d8,
        size: 20,
        color: o.side === 'SELL' ? '#E57373' : '#29a373'
      }
    };

  },
  SUMMARY_BEAUTIFY(o) {

    // START Separate into negative and positve data arrays
    const separatedPositiveNegativePnL = [[], []];
    const formattedPnL = [];

    o.PnLPerDayAccumulated.forEach((i) => {

      if (i.totalAccumulatedPnLPerDay >= 0) {

        separatedPositiveNegativePnL[0].push({
          x: i.date, y: i.totalAccumulatedPnLPerDay
        });
        separatedPositiveNegativePnL[1].push({
          x: i.date, y: null
        });

        formattedPnL.push({
          x: i.date, y: i.totalAccumulatedPnLPerDay
        });

      } else {

        separatedPositiveNegativePnL[1].push({
          x: i.date, y: i.totalAccumulatedPnLPerDay
        });
        separatedPositiveNegativePnL[0].push({
          x: i.date, y: null
        });

        formattedPnL.push({
          x: i.date, y: i.totalAccumulatedPnLPerDay
        });

      }

    });
    // END Separate into negative and positve data arrays

    if (this.CURRENCY_MEASURE() === 'R-multiple') {

      return {
        ...o,
        netPnL: this.NUMBER_TO_R_MULTIPLE(o.netPnLRMultiple),
        avgPnL: this.NUMBER_TO_R_MULTIPLE(o.avgPnLRMultiple),
        avgPnLPerDay: this.NUMBER_TO_CURRENCY(o.avgPnLPerDay),
        avgPnLPerMonth: this.NUMBER_TO_CURRENCY(o.avgPnLPerMonth),
        avgPnLPerYear: this.NUMBER_TO_CURRENCY(o.avgPnLPerYear),
        avgTradesPerDay: o.avgTradesPerDay ? o.avgTradesPerDay.toFixed(2) : '',
        avgTradesPerMonth: o.avgTradesPerMonth ? o.avgTradesPerMonth.toFixed(2) : '',
        avgTradesPerYear: o.avgTradesPerYear ? o.avgTradesPerYear.toFixed(2) : '',
        maxPnL: this.NUMBER_TO_R_MULTIPLE(o.maxPnLRMultiple),
        minPnL: this.NUMBER_TO_R_MULTIPLE(o.minPnLRMultiple),
        maxPnLPerDay: this.NUMBER_TO_CURRENCY(o.maxPnLPerDay),
        maxPnLPerMonth: this.NUMBER_TO_CURRENCY(o.maxPnLPerMonth),
        maxPnLPerYear: this.NUMBER_TO_CURRENCY(o.maxPnLPerYear),
        minPnLPerDay: this.NUMBER_TO_CURRENCY(o.minPnLPerDay),
        minPnLPerMonth: this.NUMBER_TO_CURRENCY(o.minPnLPerMonth),
        mostProfitableByTrades: { ...o.mostProfitableByTrades, symbol: o.mostProfitableByTrades.wins !== 0 ? o.mostProfitableByTrades.symbol : '' },
        avgExecutions: o.avgExecutions ? o.avgExecutions.toFixed(2) : '',
        minPnLPerYear: this.NUMBER_TO_CURRENCY(o.minPnLPerYear),
        mostProfitableByPnL: { symbol: o.mostProfitableByPnLRMultiple.symbol, PnL: this.NUMBER_TO_R_MULTIPLE(o.mostProfitableByPnLRMultiple.PnL) },
        leastProfitableByPnL: { symbol: o.leastProfitableByPnLRMultiple.symbol, PnL: this.NUMBER_TO_R_MULTIPLE(o.leastProfitableByPnLRMultiple.PnL) },
        leastProfitableByTrades: { ...o.leastProfitableByTrades, symbol: o.leastProfitableByTrades.losses !== 0 ? o.leastProfitableByTrades.symbol : '' },
        PnLPerDayAccumulated: separatedPositiveNegativePnL,
        PnLPerDayAccumulatedFormatted: formattedPnL,
        winLossRatio: o.winLossRatio ? o.winLossRatio.toFixed(2) : '',
        totalCommissions: this.NUMBER_TO_CURRENCY(o.totalCommissions)
      };

    }

    return {
      ...o,
      netPnL: this.NUMBER_TO_CURRENCY(o.netPnL),
      avgPnL: this.NUMBER_TO_CURRENCY(o.avgPnL),
      avgPnLPerDay: this.NUMBER_TO_CURRENCY(o.avgPnLPerDay),
      avgPnLPerMonth: this.NUMBER_TO_CURRENCY(o.avgPnLPerMonth),
      avgPnLPerYear: this.NUMBER_TO_CURRENCY(o.avgPnLPerYear),
      avgTradesPerDay: o.avgTradesPerDay ? o.avgTradesPerDay.toFixed(2) : '',
      avgTradesPerMonth: o.avgTradesPerMonth ? o.avgTradesPerMonth.toFixed(2) : '',
      avgTradesPerYear: o.avgTradesPerYear ? o.avgTradesPerYear.toFixed(2) : '',
      maxPnL: this.NUMBER_TO_CURRENCY(o.maxPnL),
      minPnL: this.NUMBER_TO_CURRENCY(o.minPnL),
      maxPnLPerDay: this.NUMBER_TO_CURRENCY(o.maxPnLPerDay),
      maxPnLPerMonth: this.NUMBER_TO_CURRENCY(o.maxPnLPerMonth),
      maxPnLPerYear: this.NUMBER_TO_CURRENCY(o.maxPnLPerYear),
      minPnLPerDay: this.NUMBER_TO_CURRENCY(o.minPnLPerDay),
      minPnLPerMonth: this.NUMBER_TO_CURRENCY(o.minPnLPerMonth),
      mostProfitableByTrades: { ...o.mostProfitableByTrades, symbol: o.mostProfitableByTrades.wins !== 0 ? o.mostProfitableByTrades.symbol : '' },
      avgExecutions: o.avgExecutions ? o.avgExecutions.toFixed(2) : '',
      minPnLPerYear: this.NUMBER_TO_CURRENCY(o.minPnLPerYear),
      mostProfitableByPnL: { symbol: o.mostProfitableByPnL.symbol, PnL: this.NUMBER_TO_CURRENCY(o.mostProfitableByPnL.PnL) },
      leastProfitableByPnL: { symbol: o.leastProfitableByPnL.symbol, PnL: this.NUMBER_TO_CURRENCY(o.leastProfitableByPnL.PnL) },
      leastProfitableByTrades: { ...o.leastProfitableByTrades, symbol: o.leastProfitableByTrades.losses !== 0 ? o.leastProfitableByTrades.symbol : '' },
      PnLPerDayAccumulated: separatedPositiveNegativePnL,
      PnLPerDayAccumulatedFormatted: formattedPnL,
      winLossRatio: o.winLossRatio ? o.winLossRatio.toFixed(2) : '',
      totalCommissions: this.NUMBER_TO_CURRENCY(o.totalCommissions)
    };

  },
  COMPARE_TABLE_BEAUTIFY(o) {

    // Mapping of original keys to desired names and formatting
    const keyMapping = {
      winPercentage: { newKey: 'Win %', formatter: this.TWO_DECIMALS, winnerCondition: 'max' },
      averageNetPnLWin: { newKey: 'Average Winner', formatter: this.NUMBER_TO_CURRENCY, winnerCondition: 'max' },
      averageNetPnLLoss: { newKey: 'Average Loser', formatter: this.NUMBER_TO_CURRENCY, winnerCondition: 'max' },
      avgTradesPerDay: { newKey: 'Average Trades /Day', formatter: this.TWO_DECIMALS, winnerCondition: 'max' },
      avgWinnerLoser: { newKey: 'Average Winner /Loser', formatter: this.TWO_DECIMALS, winnerCondition: 'max' },
      totalNetPnL: { newKey: 'Total P&L', formatter: this.NUMBER_TO_CURRENCY, winnerCondition: 'max' },
      maxNetPnL: { newKey: 'Max P&L', formatter: this.NUMBER_TO_CURRENCY, winnerCondition: 'max' },
      minNetPnL: { newKey: 'Min P&L', formatter: this.NUMBER_TO_CURRENCY, winnerCondition: 'max' },
      averageNetPnL: { newKey: 'Average P&L', formatter: this.NUMBER_TO_CURRENCY, winnerCondition: 'max' },
      wins: { newKey: 'Wins', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      losses: { newKey: 'Losses', formatter: this.REMOVE_DECIMALS, winnerCondition: 'min' },
      breakeven: { newKey: 'Breakeven', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      long: { newKey: 'Long', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      short: { newKey: 'Short', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      totalTrades: { newKey: 'Total Trades', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      totalClosedTrades: { newKey: 'Total Closed Trades', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      totalOpenTrades: { newKey: 'Total Open Trades', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      maxExecutionsPerTrade: { newKey: 'Max Executions Per Trade', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      minExecutionsPerTrade: { newKey: 'Min Executions Per Trade', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      totalExecutions: { newKey: 'Total Executions', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      totalVolume: { newKey: 'Total Volume', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      totalVolumeClosedTrades: { newKey: 'Total Volume Closed Trades', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      totalVolumeOpenTrades: { newKey: 'Total Volume Open Trades', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      averageExecutionsPerTrade: { newKey: 'Average Executions Per Trade', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      averageVolume: { newKey: 'Average Volume', winnerCondition: 'max' },
      maxVolume: { newKey: 'Max Volume', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      minVolume: { newKey: 'Min Volume', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      totalVolumeWin: { newKey: 'Total Volume Win', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      totalVolumeLoss: { newKey: 'Total Volume Loss', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      averageVolumeWin: { newKey: 'Average Volume Win', formatter: this.TWO_DECIMALS, winnerCondition: 'max' },
      averageVolumeLoss: { newKey: 'Average Volume Loss', formatter: this.TWO_DECIMALS, winnerCondition: 'max' },
      totalCommissionsAndFees: { newKey: 'Total Commissions And Fees', formatter: this.NUMBER_TO_CURRENCY, winnerCondition: 'max' },
      firstTrade: { newKey: 'First Trade', winnerCondition: 'max' },
      longestTradeDuration: { newKey: 'Longest Trade Duration', winnerCondition: 'max', formatter: this.HUMANIZE_DURATION },
      fastestTradeDuration: { newKey: 'Fastest Trade Duration', winnerCondition: 'max', formatter: this.HUMANIZE_DURATION },
      longestOpenTradeDuration: { newKey: 'Longest Open Trade Duration', winnerCondition: 'max', formatter: this.HUMANIZE_DURATION },
      longestWinningTradeDuration: { newKey: 'Longest Winning Trade Duration', winnerCondition: 'max', formatter: this.HUMANIZE_DURATION },
      longestLosingTradeDuration: { newKey: 'Longest Losing Trade Duration', winnerCondition: 'max', formatter: this.HUMANIZE_DURATION },
      shortestOpenTradeDuration: { newKey: 'Shortest Open Trade Duration', winnerCondition: 'max', formatter: this.HUMANIZE_DURATION },
      averageDurationPerTrade: { newKey: 'Average Duration Per Trade', winnerCondition: 'max', formatter: this.HUMANIZE_DURATION },
      averageDurationPerWinningTrade: { newKey: 'Average Duration Per Winning Trade', winnerCondition: 'max', formatter: this.HUMANIZE_DURATION },
      averageDurationPerLosingTrade: { newKey: 'Average Duration Per Losing Trade', winnerCondition: 'max', formatter: this.HUMANIZE_DURATION },
      maxConsecutiveWins: { newKey: 'Max Consecutive Wins', formatter: this.REMOVE_DECIMALS, winnerCondition: 'max' },
      maxConsecutiveLosses: { newKey: 'Max Consecutive Losses', formatter: this.REMOVE_DECIMALS, winnerCondition: 'min' }
    };

    const renamedItem = [];
    // eslint-disable-next-line no-unused-vars
    Object.entries(keyMapping).forEach(([key, value], index) => {

      const v = o.find((i) => i.key === key);
      if (!v) return;

      const { newKey } = value;
      const formatFunction = value.formatter;
      renamedItem[index] = {
        key: newKey,
        groupA: formatFunction ? formatFunction(v.groupA) : v.groupA,
        groupB: formatFunction ? formatFunction(v.groupB) : v.groupB,
        groupC: formatFunction ? formatFunction(v.groupC) : v.groupC
      };

      // Find the maximum value among all groups
      const winnerOverallValue = value.winnerCondition === 'max'
        ? Math.max(v.groupA, v.groupB, v.groupC)
        : Math.min(v.groupA, v.groupB, v.groupC);

      const winnerGroups = [];
      if (v.groupA === winnerOverallValue) winnerGroups.push('groupA');
      if (v.groupB === winnerOverallValue) winnerGroups.push('groupB');
      if (v.groupC === winnerOverallValue) winnerGroups.push('groupC');

      renamedItem[index].winner = winnerGroups;

    });

    return renamedItem;

  },
  DATE_AND_TIME_WITH_FORMAT(date, timezone) {

    return dayjs(date).tz(timezone).format('YYYY-MM-DD HH:mm:ss');

  },
  DATE_AND_TIME(date, timezone) {

    return dayjs(date).tz(timezone);

  },
  BROKER_ID_TO_LABEL(id) {

    return brokers.find((o) => o.value === id).label;

  },
  PROFIT_CALCULATION_METHOD_ID_TO_LABEL(id) {

    return profitCalculationMethods.find((o) => o.value === id).label;

  },
  NUMBER_TO_CURRENCY(value) {

    if (value === null || value === undefined) {

      return '';

    }

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);

  },
  NUMBER_TO_R_MULTIPLE(value) {

    if (value === null || value === undefined) {

      return '';

    }

    return `${value.toFixed(2)}R`;

  },
  CURRENCY_TO_NUMBER(currency) {

    try {

      if (currency === null || currency === undefined) {

        return '';

      }

      if (this.CURRENCY_MEASURE() === 'R-multiple') {

        // Extract the numeric part and convert it to a number
        return Number(currency?.replace('R', '')) || 0;

      }

      // For other cases, remove non-numeric characters and convert to a number
      return Number(currency?.replace(/[^0-9.-]+/g, '')) || 0;

    } catch (error) {

      return '';

    }

  },
  DECOMPOSE_OPTION_STRING(str) {

    if (!str) return {};

    const parts = str.split(' '); // Split the input string by space
    if (parts.length !== 4) {

      throw new Error('Invalid option string format');

    }

    const underlying = parts[0]; // Underlying symbol
    const type = parts[1]; // Option type (CALL or PUT)
    const strike = parseFloat(parts[2]); // Option strike price as a float
    const date = parts[3]; // Option expiration date

    return {
      underlying,
      type,
      strike,
      date
    };

  },
  REMOVE_DECIMALS(value) {

    // Check if the value is a number
    if (typeof value === 'number' && !Number.isNaN(value)) {

      // Round down to the nearest integer using Math.floor()
      return Math.floor(value);

    }
    // Return the value as is if it's not a number or is NaN
    return value;

  },
  TWO_DECIMALS(value) {

    // Check if the value is a number
    if (typeof value === 'number' && !Number.isNaN(value)) {

      // Round down to the nearest integer using Math.floor()
      return value.toFixed(2);

    }
    // Return the value as is if it's not a number or is NaN
    return value;

  },
  HUMANIZE(value, fn) {

    if (!value && value !== 0) {

      return '0';

    }
    const ALPHABET = 'KMBTPEZY'.split('');
    const THRESHOLD = 1e3;

    let n = Math.abs(value);
    let index = 0;
    while (n >= THRESHOLD && index + 1 < ALPHABET.length) {

      n /= THRESHOLD;
      index += 1;

    }
    if (fn) n = fn(n);
    return (value < 0 ? '-' : '') + String(index === 0 ? n : n + ALPHABET[index - 1]);

  },
  HUMANIZE_DURATION(value) {

    if (!value && value !== 0) {

      return '';

    }
    if (value === 0) {

      return '0';

    }
    return dayjs.duration(value, 'seconds').humanize();

  }
};

export const LOGS = {

  processLogs: (accounts, setAllAccounts) => {

    const merged = [];

    if (accounts && accounts.length > 0) {

      fetchLogs(accounts.map((account) => account.key)).then((data) => {

        // Merge Accounts and Logs
        for (let i = 0; i < accounts.length; i += 1) {

          const logsAssociatedWithAccount = data.filter((itmInner) => itmInner.accountRef === accounts[i].key);

          merged.push({
            ...accounts[i],
            executionsLogs: logsAssociatedWithAccount,
            numberLogFiles: logsAssociatedWithAccount.length
          });

        }

        setAllAccounts(merged);

        return merged;

      });

    } else if (accounts && accounts.length === 0) {

      setAllAccounts([]);

    }

    return merged;

  }

};

export const TRADES = {

  fetchTrades: (filter, allAccounts) => axios.get('crud/trades/filter', {
    params: {
      accounts: filter.accounts,
      tags: filter.tags,
      showAllTags: filter.showAllTags,
      symbols: filter.symbols,
      status: filter.status,
      side: filter.side,
      startDate: filter.dateRange ? filter.dateRange[0].toString() : dayjs().add(-5, 'y').toDate(),
      endDate: filter.dateRange ? filter.dateRange[1].toString() : dayjs().toDate(),
      assetType: filter.assetType
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
  }).then((response) => {

    let responseData = null;

    if (response.status === 200) {

      responseData = response.data.map((trade) => {

        const accountTimeZone = allAccounts.find((a) => a.key === trade.accountRef)?.brokerTimezone;
        return FORMAT.TRADE_BEAUTIFY(trade, accountTimeZone);

      });

    }

    return responseData;

  }).catch((error) => {

    console.error('Error:', error);

  })

};

export const API = {
  getTickers: async () => axios.get('https://tt-static-files.s3.us-west-2.amazonaws.com/tickers.json.gz').then(({ data }) => data),
  getFullSymbolList: async () => axios
    .get('api/full-symbol-list', {
      params: {
        type: 'stocks'
      }
    })
    .then(({ data }) => data.symbols),
  getAggregateData: (params) => {

    const { ticker, multiplier, timespan, fromDate, toDate } = params;
    return axios
      .get('api/aggregate-stock', {
        params: {
          ticker,
          multiplier,
          timespan,
          fromDate: dayjs(fromDate * 1000).format('YYYY-MM-DD'),
          toDate: dayjs(toDate * 1000).format('YYYY-MM-DD'),
          sortOrder: 'asc'
        }
      })
      .then(({ data }) => data.results)
      .catch(() => []);

  }
};

export const SUMMARY = {

  fetchSummary: (filter) => axios.get('stats/summary', {
    params: {
      accounts: filter.accounts,
      assetType: filter.assetType,
      tags: filter.tags,
      showAllTags: filter.showAllTags,
      symbols: filter.symbols,
      status: filter.status,
      side: filter.side,
      startDate: filter.dateRange ? filter.dateRange[0].toString() : dayjs().add(-5, 'y').toDate(),
      endDate: filter.dateRange ? filter.dateRange[1].toString() : dayjs().toDate(),
      getAllofTickers: filter.getAllofTickers
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
  }).then((response) => {

    let responseData = null;

    if (response.status === 200) {

      responseData = FORMAT.SUMMARY_BEAUTIFY(response.data);

    }

    return responseData;

  }).catch((error) => {

    console.error('Error:', error);
    return null;

  })
};
