import { React, useState } from 'react';
import { Calendar, Card, Space, Statistic, Typography } from 'antd';
import dayjs from 'dayjs';
import qs from 'qs';

import { useQuery } from '@tanstack/react-query';

import { useSettingStore } from '../../store';
import axios from '../../utilities/axios';
import { FORMAT } from '../../utilities/functions';

import './styles.scss';

const { Title, Text } = Typography;

const getMonthData = (value, data) => Object.fromEntries(
  Object.entries(data).filter(([key]) => new Date(key).getMonth() + 1 === value.month() + 1 && new Date(key).getFullYear() === value.year())
);
const getYearData = (value, data) => Object.fromEntries(Object.entries(data).filter(([key]) => new Date(key).getFullYear() === value.year()));
const normalizeValue = (value) => ((100 - 25) * (value)) / 100 + 25;

export default function CalendarPage() {

  const { filter } = useSettingStore((state) => state);
  const [currentDate, setCurrentDate] = useState(dayjs());

  const fetchAccountCalendar = () => axios.get('stats/calendar', {
    params: {
      accounts: filter.accounts,
      tags: filter.tags,
      showAllTags: filter.showAllTags,
      symbols: filter.symbols,
      status: filter.status,
      side: filter.side,
      assetType: filter.assetType
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' })
  }).then((response) => {

    let responseData = null;
    if (response.status === 200) {

      responseData = response.data;

    }

    return responseData;

  }).catch((error) => {

    console.error('Error:', error);

  });

  const { isLoading, data: calendarData } = useQuery(['calendar-account', filter], () => fetchAccountCalendar());

  const onPanelChange = (value) => setCurrentDate(value);

  const monthCellRender = (value, yearData) => {

    let color;
    let percentage;
    const cellDate = value.format('YYYY-MM');
    const totalNetPnLValues = Object.keys(yearData).map((month) => yearData[month][0].totalNetPnLPerMonth);

    const max = Math.max(...totalNetPnLValues);
    const min = Math.min(...totalNetPnLValues);

    const listData = calendarData.month[cellDate];
    const totalNetPnLPerMonth = listData ? listData[0].totalNetPnLPerMonth : null;

    if (totalNetPnLPerMonth >= 0) {

      percentage = normalizeValue((totalNetPnLPerMonth * 100) / max);
      color = `hsl(120, ${percentage}%, 50%)`;

    } else if (totalNetPnLPerMonth < 0) {

      percentage = normalizeValue((totalNetPnLPerMonth * 100) / min);
      color = `hsl(0, ${percentage}%, 50%)`;

    }
    return (
      listData &&
      <Card bordered={false} style={{ backgroundColor: color }}>
          <Statistic
              value={FORMAT.NUMBER_TO_CURRENCY(totalNetPnLPerMonth)}
              precision={2}
              valueStyle={{ color: '#000' }} />
          {/* <Tag color={color}>{FORMAT.NUMBER_TO_CURRENCY(totalNetPnLPerDay)}</Tag> */}
      </Card>
    );
    // const num = getMonthData(value);
    // return num ? (
    // <div className="notes-month">
    //   <section>1</section>
    //   <span>Backlog number</span>
    // </div>
    // ) : null;

  };
  const dateCellRender = (value, monthData) => {

    let color;
    let percentage;
    const cellDate = value.format('YYYY-MM-DD');
    const totalNetPnLValues = Object.keys(monthData).map((day) => monthData[day][0].totalNetPnLPerDay);

    const max = Math.max(...totalNetPnLValues);
    const min = Math.min(...totalNetPnLValues);

    const listData = calendarData.day[cellDate];
    const totalNetPnLPerDay = listData ? listData[0].totalNetPnLPerDay : null;

    if (totalNetPnLPerDay >= 0) {

      percentage = normalizeValue((totalNetPnLPerDay * 100) / max);
      color = `hsl(160, ${percentage}%, 47%)`;

    } else if (totalNetPnLPerDay < 0) {

      percentage = normalizeValue((totalNetPnLPerDay * 100) / min);
      color = `hsl(0, ${percentage}%, 72%)`;

    }
    return (
      listData &&
      <Card bordered={false} style={{ backgroundColor: color }}>
          <Statistic
              value={FORMAT.NUMBER_TO_CURRENCY(totalNetPnLPerDay)}
              precision={2}
              valueStyle={{ color: '#000' }} />
          {/* <Tag color={color}>{FORMAT.NUMBER_TO_CURRENCY(totalNetPnLPerDay)}</Tag> */}
      </Card>
    );

  };
  const cellRender = (current, info) => {

    const monthData = getMonthData(currentDate, calendarData.day);
    const yearData = getYearData(currentDate, calendarData.month);
    if (info.type === 'date') return dateCellRender(current, monthData);
    if (info.type === 'month') return monthCellRender(current, yearData);
    return info.originNode;

  };

  if (isLoading) return <></>;

  if (!calendarData) {

    return (
        <Space direction='vertical' className='no-data' wrap>
            <Title>No data available.</Title>
            <Text>Select different filters or upload trade logs by using the <strong>Import Trades</strong> button.</Text>
        </Space>
    );

  }

  return (
      <>
          {calendarData && <Calendar className='main-calendar' onPanelChange={onPanelChange} cellRender={cellRender} />}

      </>
  );

}
