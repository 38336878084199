import React, { useState } from 'react';
import { Button, Pagination } from 'antd';
import dayjs from 'dayjs';
import qs from 'qs';

import { useQuery } from '@tanstack/react-query';

import { useSettingStore, useUserState } from '../../store';
import axios from '../../utilities/axios';

import DayLogModalPage from './day-log-modal';
import DayNoteTradeCard from './day-note-trade-card';

import './styles.scss';

export default function DailyNotesPage() {

  const { getUser } = useUserState();
  const user = getUser();

  const { filter } = useSettingStore((state) => state);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectDayTrade, setSelectDayTrade] = useState(null);

  const pageSize = 5;

  const fetchDailyTrades = () => axios.get('crud/daily-trade-notes', {
    params: {
      accounts: filter.accounts,
      tags: filter.tags,
      showAllTags: filter.showAllTags,
      symbols: filter.symbols,
      status: filter.status,
      side: filter.side,
      assetType: filter.assetType,
      dateFrom: filter.dateRange ? dayjs(filter.dateRange[0]).format('YYYY-MM-DD') : '',
      dateTo: filter.dateRange ? dayjs(filter.dateRange[1]).format('YYYY-MM-DD') : ''
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
    headers: {
      token: user?.token
    }
  }).then((response) => {

    let responseData = null;
    if (response.status === 200) {

      responseData = response.data.filter((x) => x.dateFormat);

    }

    return responseData;

  }).catch((error) => {

    console.error('Error:', error);

  });

  const { isLoading, data } = useQuery(['daily-trade-notes', filter], () => fetchDailyTrades());

  return (
      <div className='dailyNotes'>

          <div className='dayNoteHeader'>
              <h2>Daily Notes</h2>
              <Button
                  className='submit-btn'
                  type='primary'
                  size='middle'
                  onClick={() => {

                    setIsModalOpen(true);
                    setSelectDayTrade(null);

                  }}
        >
                  <span>+</span> Day log</Button>
          </div>
          {isLoading === false &&
          <>
              <div className='trades'>
                  {data?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
                    .map((item, key) => <DayNoteTradeCard
                        dailyTradeNote={item}
                        index={key}
                        key={key}
                        isInList={true}
                        setSelectDayTrade={setSelectDayTrade}
                        setIsModalOpen={setIsModalOpen}
                    />)}

                  <Pagination
                      current={currentPage}
                      total={data?.length}
                      showSizeChanger={false}
                      showQuickJumper={false}
                      onChange={(page) => setCurrentPage(page)}
                      hideOnSinglePage={true}
        />

              </div>

              <DayLogModalPage
                  disabledDates={data?.map((x) => x.date)}
                  dailyTradeNote={selectDayTrade}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen} />
          </>
      }

      </div>
  );

}
